import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Y from 'yjs';
import { StoreApi, createStore } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { usePrompt } from '@studio/lib';
import { VideoProjectStatus } from '@lib/gql/graphql';
import {
  ElementOption,
  PROJECT_ELEMENT,
  PROJECT_STATUS,
  ProjectElementType,
  ProjectMeta,
  ProjectNameInspiration,
  ProjectStatusType,
  TextOptions,
  ThumbnailOptions,
} from '@lib/types';
import { formatTimestamp } from '@lib/utils';
import { SetRoomProvider } from './room-provider';
import { YDocStoreContext } from './ydoc-provider';

export function addToYMap(yMap: Y.Map<unknown>, obj: object) {
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const yArray = new Y.Array();
      addToYArray(yArray, value);
      yMap.set(key, yArray);
    } else if (value !== null && typeof value === 'object') {
      const subMap = new Y.Map();
      addToYMap(subMap, value);
      yMap.set(key, subMap);
    } else if (typeof value === 'string') {
      yMap.set(key, new Y.Text(value));
    } else {
      yMap.set(key, value);
    }
  });
}

export function addToYArray(yArray: Y.Array<unknown>, arr: Array<unknown>) {
  arr.forEach((item) => {
    if (Array.isArray(item)) {
      const subArray = new Y.Array();
      addToYArray(subArray, item);
      yArray.push([subArray]);
    } else if (item !== null && typeof item === 'object') {
      const subMap = new Y.Map();
      addToYMap(subMap, item);
      yArray.push([subMap]);
    } else {
      yArray.push([item]);
    }
  });
}

function updateYDocFromJson(
  data: Record<string, unknown>,
  rootMap: string,
  doc: Y.Doc
) {
  doc.transact(() => {
    const ymap = doc.getMap(rootMap);
    addToYMap(ymap, data);
    return doc;
  }, 'zustand_middleware');
}

export type ProjectStateType = {
  id: string;
  meta: ProjectMeta;
  title: TextOptions;
  concept: TextOptions;
  thumbnail: ThumbnailOptions;
  updateId: (value: string) => void;
  updateThumbnailPrompt: (prompt: string) => void;
  updateMeta: (
    field: keyof ProjectMeta,
    value: ProjectStatusType | string | string[] | boolean | undefined
  ) => void;
  updateElement: (
    element: ProjectElementType,
    content: string,
    uuid?: string
  ) => void;
  selectElement: (
    element: ProjectElementType,
    content: ElementOption,
    uuid?: string
  ) => void;
  isSelected: (element: ProjectElementType, uuid: string) => boolean;
  setElementPrimary: (element: ProjectElementType, uuid: string) => void;
  removeElementFromPrimary: (element: ProjectElementType) => void;
  deleteElement: (element: ProjectElementType, uuid: string) => void;
  getNonPrimaryOptions: (element: ProjectElementType) => ElementOption[];
  getPrimary: (type: ProjectElementType) => string | undefined;
  getPrimaryOption: (type: ProjectElementType) => ElementOption | undefined;
};

export const ProjectStoreContext = createContext<{
  projectStore: StoreApi<ProjectStateType>;
}>(null!);

// "Video project 10.15.2024-14:47"
// obviously must update the below regex if default name format ever changes.
const PROJECT_DEFAULT_NAME = (timestamp: string) =>
  `Video project ${timestamp}`;
const PROJECT_DEFAULT_NAME_REGEX = new RegExp(
  /^Video project \d{2}.\d{2}.\d{4}-\d{2}:\d{2}$/gm
);

/**
 * TODO: This store is becoming far too imperative.
 * Post launch we need to rethink this API and
 * move to a more functional approach
 */
const createProjectStore = (doc: Y.Doc) => {
  const videoProjectJSON = doc.getMap('videoProject').toJSON();
  const timestamp = formatTimestamp();

  videoProjectJSON.meta =
    videoProjectJSON.meta ||
    ({
      name: PROJECT_DEFAULT_NAME(timestamp),
      nameSetOrGenerated: false,
      status: PROJECT_STATUS.IDEA as VideoProjectStatus,
      isPublic: true,
    } as ProjectMeta);

  return createStore<ProjectStateType>()(
    subscribeWithSelector(
      devtools(
        (set, get) => ({
          id: videoProjectJSON.id,
          meta: {
            ...videoProjectJSON.meta,
            status: videoProjectJSON.meta?.status || PROJECT_STATUS.IDEA,
          },
          title: {
            primary: videoProjectJSON.title?.primary || '',
            options: videoProjectJSON.title?.options || [],
          },
          concept: {
            primary: videoProjectJSON.concept?.primary || '',
            options: videoProjectJSON.concept?.options || [],
          },
          thumbnail: {
            primary: videoProjectJSON.thumbnail?.primary || '',
            options: videoProjectJSON.thumbnail?.options || [],
            prompt: videoProjectJSON.thumbnail?.prompt || '',
          },
          updateId: (value) => {
            set(() => ({ id: value }));
          },
          updateThumbnailPrompt: (value) => {
            set(
              (state) => ({
                thumbnail: {
                  ...state.thumbnail,
                  prompt: value,
                },
              }),
              false,
              'project/update-thumbnail-prompt'
            );
          },
          updateMeta: (field, value) => {
            set(
              (state) => {
                const meta = {
                  ...state.meta,
                  [field]: value,
                };

                // user has edited the project name from default.
                // note this so that name generation doesn't take place.
                if (field === 'name' && !meta.nameSetOrGenerated) {
                  meta.nameSetOrGenerated = true;
                }

                return {
                  ...state,
                  meta,
                };
              },
              false,
              'project/update-meta'
            );
          },
          updateElement: (element, content, uuid) => {
            set(
              (state) => {
                let options = state[element].options || [];
                const existingIndex = options.findIndex(
                  (option) => option.id === uuid
                );
                const hasExistingIndex = existingIndex !== -1;

                if (hasExistingIndex) {
                  const userDeletedContent =
                    options[existingIndex].content.length && !content.length;

                  // If the user deletes the content of their title, remove it.
                  if (userDeletedContent) {
                    options = options.filter((option) => option.id !== uuid);
                  } else {
                    options[existingIndex] = {
                      ...options[existingIndex],
                      content: content,
                    };
                  }
                } else {
                  // If not found, create a new option
                  const newOption = {
                    id: uuid || uuidv4(),
                    content: content,
                  };
                  options.unshift(newOption);
                  state[element].primary = newOption.id;
                }

                return {
                  ...state,
                  [element]: {
                    ...state[element],
                    options: [...options],
                  },
                };
              },
              false,
              'project/update-element'
            );
          },
          selectElement: (element, content, uuid) => {
            set(
              (state) => {
                const options = state[element].options || [];
                const existingOption = options.some(
                  (option) => option.id === uuid
                );
                if (existingOption) {
                  return {
                    ...state,
                  };
                }
                // If not found, create a new option
                const newOption = {
                  ...content,
                  id: uuid || uuidv4(),
                };
                // If no options, set new as primary
                if (options.length === 0) {
                  state[element].primary = newOption.id;
                }
                options.unshift(newOption);

                // if the project name is of the default form ("Video Project {dateTime}"),
                // auto-gen a new and better name
                const meta = state.meta;

                if (
                  !meta.nameSetOrGenerated &&
                  (state.meta.name ?? '').match(PROJECT_DEFAULT_NAME_REGEX)
                ) {
                  const inspiration: ProjectNameInspiration = {};

                  if (element === 'thumbnail') {
                    inspiration.primaryThumbnail = content.description;
                  } else if (element === 'concept') {
                    inspiration.primaryConcept = content.content;
                  } else if (element === 'title') {
                    inspiration.primaryTitle = content.content;
                  }

                  // future-proofing
                  if (Object.keys(inspiration).length > 0) {
                    meta.nameGenInspiration = inspiration;
                    // flip the state-only bool to prevent fast successive element selection
                    // from calling the async generateProjectName() more than once.
                    meta.nameSetOrGenerated = true;
                  }
                }

                return {
                  ...state,
                  meta,
                  [element]: {
                    ...state[element],
                    options,
                  },
                };
              },
              false,
              'project/select-element'
            );
          },
          isSelected: (element, id) =>
            !!get()[element].options?.find((option) => option.id === id),
          setElementPrimary: (element, primary) => {
            set(
              (state) => ({
                [element]: {
                  ...state[element],
                  primary,
                },
              }),
              false,
              'project/set-primary-element'
            );
          },
          removeElementFromPrimary: (element) => {
            set(
              (state) => ({
                [element]: {
                  ...state[element],
                  primary: '',
                },
              }),
              false,
              'project/remove-primary-element'
            );
          },
          deleteElement: (element, uuid) => {
            set(
              (state) => {
                const options = state[element].options || [];
                const newOptions = options.filter(
                  (option) => option.id !== uuid
                );
                const isPrimary = state[element].primary === uuid;
                const newPrimary = newOptions?.[0]?.id ?? '';
                return {
                  ...state,
                  [element]: {
                    ...state[element],
                    options: newOptions,
                    primary: isPrimary ? newPrimary : state[element].primary,
                  },
                };
              },
              false,
              'project/delete-element'
            );
          },
          getPrimary: (type) => {
            const typeContainer = get()[type];
            const primaryOption = typeContainer?.options?.find(
              (option) => option.id === typeContainer.primary
            );
            if (type === PROJECT_ELEMENT.THUMBNAIL) {
              return primaryOption?.description || get().thumbnail?.prompt;
            }
            return primaryOption?.content;
          },
          getNonPrimaryOptions: (type) => {
            const typeContainer = get()[type];
            return (
              typeContainer?.options?.filter(
                (option) => option.id !== typeContainer.primary
              ) || []
            );
          },
          getPrimaryOption: (type) => {
            const typeContainer = get()[type];
            return typeContainer?.options?.find(
              (option) => option.id === typeContainer.primary
            );
          },
        }),
        { name: 'ideation' }
      )
    )
  );
};

const pluckRelevantFields = (state: ProjectStateType) => {
  const meta = {
    ...state.meta,
    status: state.meta?.status || PROJECT_STATUS.IDEA,
  };
  // used by project store only - no need to persist to the b/e.
  delete meta.nameSetOrGenerated;
  delete meta.nameGenInspiration;

  return {
    id: state.id,
    meta,
    title: {
      primary: state.title?.primary || '',
      options: state.title?.options || [],
    },
    concept: {
      primary: state.concept?.primary || '',
      options: state.concept?.options || [],
    },
    thumbnail: {
      primary: state.thumbnail?.primary || '',
      options: state.thumbnail?.options || [],
      prompt: state.thumbnail?.prompt || '',
    },
  };
};

export const ProjectStoreProvider = ({ children }: { children: ReactNode }) => {
  /**
   * we create the store in the context because we need access to the ydoc when we create it
   * creating a new ydoc and store in the page context reduces the complexity
   * of managing one global ydoc, its state, and its connection to liveblocks
   */
  const { doc } = useContext(YDocStoreContext);
  const { firstInRoom } = useContext(SetRoomProvider);
  const [store] = useState(createProjectStore(doc));

  const { send: generateProjectName } = usePrompt(
    'project',
    {
      // data will get passed at exec time via send()
      data: {},
      eventName: 'Auto-Generate Project Name',
      variant: 'name',
    },
    {
      onComplete: (result) => {
        const json = JSON.parse(result);
        const name = json?.result;

        if (!name) return;

        store.setState({
          meta: {
            ...store.getState().meta,
            name,
          },
        });
      },
    }
  );

  useEffect(() => {
    if (firstInRoom.isMe) {
      const unsubProject = store.subscribe(
        (state) => pluckRelevantFields(state),
        (videoProject) => {
          updateYDocFromJson(videoProject, 'videoProject', doc);
        }
      );

      const unsubNameGen = store.subscribe(
        (state) => state.meta.nameGenInspiration,
        (nameGenInspiration) => {
          if (Object.keys(nameGenInspiration ?? {}).length > 0) {
            const data = {
              project: nameGenInspiration,
            };
            generateProjectName(data);
          }
        }
      );

      return () => {
        unsubProject();
        unsubNameGen?.();
      };
    }
  }, [store, firstInRoom]);

  // sync project state on take over of the page
  useEffect(() => {
    // if i'm not in control of the room i want to see the others changes in real time.
    const map = doc.getMap('videoProject');
    if (!firstInRoom.isMe) {
      const handler = () => {
        store.setState(map.toJSON());
      };
      map.observeDeep(handler);
      return () => map.unobserveDeep(handler);
    }
    // If i am in control of the room i need to subscribe to thumbnail descriptions from the backend
    const handler = () => {
      const thumbs = doc
        .getMap('videoProject')
        .get('thumbnail') as Y.Map<ThumbnailOptions>;
      const thumbState = store.getState().thumbnail;
      if (JSON.stringify(thumbState) !== JSON.stringify(thumbs.toJSON())) {
        store.setState({ thumbnail: thumbs.toJSON() });
      }
    };
    map.observeDeep(handler);
    return () => map.unobserveDeep(handler);
  }, [firstInRoom]);

  return (
    <ProjectStoreContext.Provider value={{ projectStore: store }}>
      {children}
    </ProjectStoreContext.Provider>
  );
};
