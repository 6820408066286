import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleFilter } from '@studio/components';
import { ParamsObject, useParamStore } from '@studio/stores';
import { Form } from '@lib/ui';
import { NumberParser } from '@lib/utils';
import { ParamId } from '../types';

function serializeValue(value: number) {
  if (!value) {
    return '';
  }
  // prettier-ignore
  const n = Intl.NumberFormat('en-us', { maximumFractionDigits: 0 }).format(value);
  return n;
}

function deserializeValue(value: string) {
  if (!value) {
    return NaN;
  }
  const parser = new NumberParser('en');
  const s = parser.parse(value);
  return Number(s);
}

type Props = {
  minId: ParamId;
  maxId: ParamId;
  onChangeEventName: string;
};

export function VideoViewsFilterInput({
  minId,
  maxId,
  onChangeEventName,
}: Props) {
  const { params, setParamsTracked, removeParamTracked } = useParamStore();
  const { t } = useTranslation();

  const handleSetParams = (value: ParamsObject) => {
    setParamsTracked(onChangeEventName, value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    for (const [key, value] of formData.entries()) {
      if (!value && !params[key]) {
        return;
      }
      setParamsTracked(onChangeEventName, {
        [key]: deserializeValue(value as string).toString(),
      });
    }
  };

  const removeAndTrackParams = (value: string) => {
    removeParamTracked(onChangeEventName, value);
  };

  return (
    <Form.Root onSubmit={handleSubmit}>
      <ToggleFilter
        min={0}
        minId={minId}
        minLabel={t('Minimum')}
        minPlaceholder="0"
        max={Infinity}
        maxId={maxId}
        maxLabel={t('Maximum')}
        maxPlaceholder={'1,000,000,000'}
        params={params}
        serializeValue={serializeValue}
        deserializeValue={deserializeValue}
        setParams={handleSetParams}
        removeParam={removeAndTrackParams}
      />
      <Form.Submit />
    </Form.Root>
  );
}
