import { useTranslation } from 'react-i18next';
import { useFeatureGate } from '@statsig/react-bindings';
import { VideoDurationFilterInput } from '@studio/components/filters/inputs';
import { getDirtyFiltersCount } from '@studio/components/filters/utils';
import { useParamStore } from '@studio/stores';
import { Badge, Button, Icons, Popover, Text } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import {
  DEFAULT_VALUES,
  DEFAULT_VALUES_IDEA_BANK,
  PARAM_KEYS,
} from '../../constants';
import { PROJECTS_FILTER_VIDEO_DURATION_CHANGE } from '../../heap.constants';
import { SponsorFilterInput } from './filters/inputs/sponsor-filter-input';
import * as Styles from './header.css';

export function UnpublishedOverflowFilters() {
  const { t } = useTranslation();
  const { params } = useParamStore();
  const { value: isIdeaBankEnabled } = useFeatureGate(
    STATSIG_FEATURE_FLAGS.STUDIO_IDEA_BANK
  );

  // if any other filters are added to this overflow components, add their params here
  const filterParams = {
    ...(params.duration__gte
      ? { [PARAM_KEYS.DURATION_GTE]: params.duration__gte }
      : {}),
    ...(params.duration__lte
      ? { [PARAM_KEYS.DURATION_LTE]: params.duration__lte }
      : {}),
    ...(params.sponsors__exists
      ? { [PARAM_KEYS.SPONSORS_EXISTS]: params.sponsors__exists }
      : {}),
  };

  // count duration with both values as only one filter
  if (filterParams.duration__gte && filterParams.duration__lte) {
    delete filterParams.duration__gte;
  }

  const projectsDefaultValues = isIdeaBankEnabled
    ? DEFAULT_VALUES_IDEA_BANK
    : DEFAULT_VALUES;

  const dirtyFilters = getDirtyFiltersCount(
    filterParams,
    projectsDefaultValues
  );

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          variant="subtle"
          size="sm"
          adornmentEnd={<Icons.ChevronDownIcon aria-hidden />}
        >
          {t('More')}
          {!!dirtyFilters && (
            <Badge variant="primary" pill className={Styles.CountBadge}>
              {dirtyFilters}
            </Badge>
          )}
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="start" className={Styles.PopoverContent}>
          <Popover.Close />
          <div className={Styles.MoreFilters}>
            <div className={Styles.MoreFilter}>
              <Text size="14" weight="normal">
                {t('Video Length')}
              </Text>
              <VideoDurationFilterInput
                minId={PARAM_KEYS.DURATION_GTE}
                maxId={PARAM_KEYS.DURATION_LTE}
                onChangeEventName={PROJECTS_FILTER_VIDEO_DURATION_CHANGE}
              />
            </div>
            <div className={Styles.MoreFilter}>
              <Text size="14" weight="normal">
                {t('Sponsor')}
              </Text>
              <SponsorFilterInput />
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
