import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const BASE_PATH = '/assets/yt-preview-';

export enum PreviewTheme {
  Light = 'light',
  Dark = 'dark',
}

export enum PreviewType {
  Desktop = 'desktop',
  Mobile = 'mobile',
  YoutubeTV = 'tv',
}

export enum PreviewState {
  Minimized = 'minimized',
  Maximized = 'maximized',
}

export enum PreviewLocation {
  Home = 'home',
  Search = 'search',
  Suggested = 'suggested',
}

type PreviewStore = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  theme: PreviewTheme;
  setTheme: (theme: PreviewTheme) => void;
  previewType: PreviewType;
  setPreviewType: (type: PreviewType) => void;
  previewState: PreviewState;
  togglePreviewState: (previewState: PreviewState) => void;
  previewLocation: PreviewLocation;
  setPreviewLocation: (location: PreviewLocation) => void;
  mainItemPosition: number;
  setMainItemPosition: (position: number) => void;
  getOverlayImgSrc: () => string;
  squintMode: boolean;
  setSquintMode: (mode: boolean) => void;
};

export const usePreviewStore = create<PreviewStore>()(
  devtools(
    (set, get) => ({
      open: false,
      setOpen: (isOpen: boolean) => {
        set(
          () => {
            return {
              open: isOpen,
              previewState: isOpen
                ? get().previewState
                : PreviewState.Maximized,
            };
          },
          false,
          isOpen ? 'preview/open-modal' : 'preview/close-modal'
        );
      },
      theme: PreviewTheme.Dark,
      setTheme: (theme: PreviewTheme) => {
        set(
          () => {
            return { theme };
          },
          false,
          'preview/set-theme'
        );
      },
      previewType: PreviewType.Desktop,
      setPreviewType: (type: PreviewType) => {
        set(
          () => {
            return { previewType: type };
          },
          false,
          'preview/set-preview-type'
        );
      },
      previewState: PreviewState.Minimized,
      togglePreviewState: (previewState: PreviewState) => {
        set(
          () => {
            return {
              previewState,
            };
          },
          false,
          'preview/toggle-preview-state'
        );
      },
      previewLocation: PreviewLocation.Home,
      setPreviewLocation: (location: PreviewLocation) => {
        set(
          () => {
            return { previewLocation: location };
          },
          false,
          'preview/set-preview-location'
        );
      },
      mainItemPosition: 1,
      setMainItemPosition: (position: number) => {
        set(
          { mainItemPosition: position },
          false,
          'preview/set-main-item-position'
        );
      },
      getOverlayImgSrc: () => {
        const previewType = get().previewType;
        const theme = get().theme;
        const location = get().previewLocation;

        switch (previewType) {
          case PreviewType.YoutubeTV:
            return `${BASE_PATH}${previewType}.jpg`;
          case PreviewType.Desktop:
            return `${BASE_PATH}${previewType}-${location}-${theme}.jpg`;
          case PreviewType.Mobile:
            return `${BASE_PATH}${previewType}-${theme}.jpg`;
        }
      },
      squintMode: false,
      setSquintMode: (mode: boolean) => {
        set({ squintMode: mode }, false, 'preview/set-squint-mode');
      },
    }),
    { name: 'preview' }
  )
);
