import { useFeatureGate } from '@statsig/react-bindings';
import { PROJECT_STATUS, PROJECT_STATUS_ITEMS } from '@lib/types';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import {
  PublishDateFilter,
  ScopeFilter,
  StatusFilter,
  UnpublishedOverflowFilters,
} from './';

// Ideas and Published are presented in their own tabs
const statusItemsSansIdeaAndPublished = PROJECT_STATUS_ITEMS.filter(
  (item) =>
    item.value !== PROJECT_STATUS.PUBLISHED &&
    item.value !== PROJECT_STATUS.IDEA
);

const statusItemsSansPublished = PROJECT_STATUS_ITEMS.filter(
  (item) => item.value !== PROJECT_STATUS.PUBLISHED
);

export function FiltersUnpublished() {
  const { value: ideaBankEnabled } = useFeatureGate(
    STATSIG_FEATURE_FLAGS.STUDIO_IDEA_BANK
  );

  return (
    <>
      <StatusFilter
        items={
          ideaBankEnabled
            ? statusItemsSansIdeaAndPublished
            : statusItemsSansPublished
        }
      />
      <ScopeFilter />
      <PublishDateFilter />
      <UnpublishedOverflowFilters />
    </>
  );
}
