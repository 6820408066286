import { useExperiment } from '@statsig/react-bindings';
import { STATSIG_AB_TESTS } from '@lib/utils';

export const usePricingPlanABTest = () => {
  const { value } = useExperiment(STATSIG_AB_TESTS.PRICING_PLAN_AB_TEST_ID);

  const showTestFeature = value?.prioritize_annual as boolean;

  return {
    showTestFeature,
    value,
  };
};
