import { transparentize } from 'polished';
import { colors as systemColors } from './colors';
import { convertColorsToRGB } from './utils';

/**
 * Note: These mostly line up with YouTube's current breakpoints
 * with an allowance for our sidebar's expanded width
 */
export const breakpoints = {
  xs: '(width >= 800px)',
  sm: '(width >= 1200px)',
  md: '(width >= 1600px)',
  lg: '(width >= 1900px)',
  xl: '(width >= 2200px)',
} as const;

export const colors = {
  amber100: systemColors.amber['100'],
  black: systemColors.black['1000'],
  black100: systemColors.black['900'],
  black200: systemColors.black['800'],
  black300: systemColors.black['700'],
  black500: systemColors.black['600'],
  black600: systemColors.black['500'],
  black700: systemColors.black['400'],
  black800: systemColors.black['300'],
  black900: systemColors.black['200'],
  blue550: systemColors.blue['500'],
  blue300: systemColors.blue['300'],
  gray50: systemColors.grey['50'],
  gray100: systemColors.grey['100'],
  gray200: systemColors.grey['150'],
  gray300: systemColors.grey['200'],
  gray400: systemColors.grey['400'],
  gray500: systemColors.grey['500'],
  gray600: systemColors.grey['800'],
  gray700: systemColors.grey['850'],
  gray750: systemColors.grey['900'],
  gray800: systemColors.grey['950'],
  gray900: systemColors.black['400'],
  green50: systemColors.green['50'],
  green100: systemColors.green['100'],
  green150: systemColors.green['150'],
  green200: systemColors.green['200'],
  green250: systemColors.success['200'],
  green300: systemColors.success['300'],
  green400: systemColors.success['400'],
  green500: systemColors.success['500'],
  green600: systemColors.success['600'],
  green700: systemColors.success['700'],
  green800: systemColors.success['800'],
  orange50: systemColors.melon['50'],
  orange200: systemColors.melon['100'],
  orange300: systemColors.melon['500'],
  orange400: systemColors.orange['400'],
  orange500: systemColors.orange['500'],
  orange600: systemColors.melon['800'],
  orange700: systemColors.melon['950'],
  purple100: systemColors.purple['50'],
  purple200: systemColors.purple['100'],
  purple300: systemColors.purple['150'],
  purple400: systemColors.purple['300'],
  purple500: systemColors.purple['500'],
  purple600: systemColors.purple['600'],
  red10: systemColors.red['50'],
  red100: systemColors.red['100'],
  red200: systemColors.red['200'],
  red300: systemColors.red['300'],
  red350: systemColors.red['400'],
  red400: systemColors.red['700'],
  red500: systemColors.red['800'],
  red600: systemColors.red['900'],
  red700: systemColors.red['950'],
  yellowAlt: systemColors.lime['200'],
  yellow50: systemColors.yellow['50'],
  yellow200: systemColors.yellow['200'],
  yellow300: systemColors.melon['200'],
  yellow400: systemColors.melon['400'],
  yellow500: systemColors.melon['600'],
  yellow600: systemColors.melon['800'],
  white: systemColors.grey['0'],
} as const;

export const colorsRGB = convertColorsToRGB(colors);

export const depths = {
  // TODO: Update these to use the new colors
  depth0: colors.black100,
  depth1: colors.black200,
  depth2: colors.black300,
  depth4: colors.black500,
  depth5: colors.black600,
  depth6: colors.black700,
  depth7: colors.black800,
  depth8: colors.black900,
  depth9: colors.black900,
} as const;

export const depthsRGB = convertColorsToRGB(depths);

export const tints = {
  /** Black Tints */
  black10: 'rgba(0, 0, 0, .1)',
  black20: 'rgba(0, 0, 0, .2)',
  black30: 'rgba(0, 0, 0, .3)',
  black40: 'rgba(0, 0, 0, .4)',
  black50: 'rgba(0, 0, 0, .5)',
  black60: 'rgba(0, 0, 0, .6)',
  black70: 'rgba(0, 0, 0, .7)',
  black80: 'rgba(0, 0, 0, .8)',
  black90: 'rgba(0, 0, 0, .9)',
  /** Dark Tints */
  dark10: 'rgba(20, 20, 20, .1)',
  dark20: 'rgba(20, 20, 20, .2)',
  dark30: 'rgba(20, 20, 20, .3)',
  dark40: 'rgba(20, 20, 20, .4)',
  dark50: 'rgba(20, 20, 20, .5)',
  dark60: 'rgba(20, 20, 20, .6)',
  dark70: 'rgba(20, 20, 20, .7)',
  dark80: 'rgba(20, 20, 20, .8)',
  dark90: 'rgba(20, 20, 20, .9)',
  /** Light Tints */
  light10: 'rgba(250, 250, 250, .1)',
  light20: 'rgba(250, 250, 250, .2)',
  light30: 'rgba(250, 250, 250, .3)',
  light40: 'rgba(250, 250, 250, .4)',
  light50: 'rgba(250, 250, 250, .5)',
  /** White Tints */
  white05: 'rgba(255, 255, 255, .05)',
  white10: 'rgba(255, 255, 255, .1)',
  white20: 'rgba(255, 255, 255, .2)',
  white30: 'rgba(255, 255, 255, .3)',
  white40: 'rgba(255, 255, 255, .4)',
  white50: 'rgba(255, 255, 255, .5)',
  white60: 'rgba(255, 255, 255, .6)',
  white70: 'rgba(255, 255, 255, .7)',
  white80: 'rgba(255, 255, 255, .8)',
  white90: 'rgba(255, 255, 255, .9)',
} as const;

export const fontFamilies = {
  body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  heading:
    '"TWK Lausanne", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
} as const;

export const fontWeights = {
  light: '200',
  normal: '400',
  medium: '500',
  bold: '700',
} as const;

export const fontScales = {
  /** 8px */
  text08: '0.5rem',
  /** 10px */
  text10: '0.625rem',
  /** 11px */
  text11: '0.6875rem',
  /** 12px */
  text12: '0.75rem',
  /** 13px */
  textDefault: '0.8125rem',
  /** 14px */
  text14: '0.875rem',
  /** 16px */
  text16: '1rem',
  /** 18px */
  text18: '1.125rem',
  /** 20px */
  text20: '1.25rem',
  /** 22px */
  text22: '1.375rem',
  /** 24px */
  text24: '1.5rem',
  /** 28px */
  text28: '1.75rem',
  /** 30px */
  text30: '1.875rem',
  /** 32px */
  text32: '2rem',
  /** 36px */
  text36: '2.25rem',
  /** 48px */
  text48: '3rem',
} as const;

export const lineHeights = {
  /** Line height 1 */
  l1: '1',
  /** Line height 1.2 */
  l2: '1.2',
  /** Line height 1.3 */
  l3: '1.3',
  /** Line height 1.4 */
  l4: '1.4',
  /** Line height 1.5 */
  l5: '1.5',
} as const;

export const outlines = {
  focus: `2px solid ${tints.white20}`,
  light: `2px solid ${colors.white}`,
  minimal: `1px solid ${colors.white}`,
} as const;

export const radii = {
  /** 8px */
  default: '0.5rem',
  /** 0px */
  r0: '0',
  /** 2px */
  r02: '0.125rem',
  /** 4px */
  r04: '0.25rem',
  /** 6px */
  r06: '0.375rem',
  /** 8px */
  r08: '0.5rem',
  /** 10px */
  r10: '0.625rem',
  /** 12px */
  r12: '0.75rem',
  /** 14px */
  r14: '0.875rem',
  /** 16px */
  r16: '1rem',
  /** 18px */
  r18: '1.125rem',
  /** 20px */
  r20: '1.125rem',
  /** 24px */
  r24: '1.5rem',
  /** 28px */
  r28: '1.75rem',
  /** 9999px */
  pill: '9999px',
} as const;

export const scales = {
  /** 1px */
  s01: '1px',
  /** 2px */
  s02: '0.125rem',
  /** 4px */
  s03: '0.1875rem',
  /** 4px */
  s04: '0.25rem',
  /** 6px */
  s06: '0.375rem',
  /** 8px */
  s08: '0.5rem',
  /** 10px */
  s10: '0.625rem',
  /** 12px */
  s12: '0.75rem',
  /** 14px */
  s14: '0.875rem',
  /** 16px */
  s16: '1rem',
  /** 18px */
  s18: '1.125rem',
  /** 20px */
  s20: '1.25rem',
  /** 22px */
  s22: '1.375rem',
  /** 24px */
  s24: '1.5rem',
  /** 26px */
  s26: '1.625rem',
  /** 28px */
  s28: '1.75rem',
  /** 30px */
  s30: '1.875rem',
  /** 32px */
  s32: '2rem',
  /** 34px */
  s34: '2.125rem',
  /** 36px */
  s36: '2.25rem',
  /** 38px */
  s38: '2.375rem',
  /** 40px */
  s40: '2.5rem',
  /** 42px */
  s42: '2.625rem',
  /** 44px */
  s44: '2.75rem',
  /** 48px */
  s48: '3rem',
  /** 58px */
  s58: '3.625rem',
  /** 64px */
  s64: '4rem',
  /** 72px */
  s72: '4.5rem',
  /** 80px */
  s80: '5rem',
  /** 96px */
  s96: '6rem',
} as const;

export const sizes = {
  /** 50% */
  '1/2': '50%',
  /** 33.333% */
  '1/3': '33.333%',
  /** 66.666% */
  '2/3': '66.666%',
  /** 25% */
  '1/4': '25%',
  /** 75% */
  '3/4': '75%',
  /** 20% */
  '1/5': '20%',
  /** 40% */
  '2/5': '40%',
  /** 60% */
  '3/5': '60%',
  /** 80% */
  '4/5': '80%',
  /** 00% */
  full: '100%',
} as const;

export const easing = {
  /** Ease in: Quint */
  in: 'cubic-bezier(0.64, 0, 0.78, 0)',
  /** Ease out: Quint */
  out: 'cubic-bezier(0.22, 1, 0.36, 1)',
  /** Ease in out: Quint */
  inOut: 'cubic-bezier(0.83, 0, 0.17, 1)',
} as const;

export const timings = {
  in: {
    /** 250ms */
    short: '250ms',
    /** 300ms */
    medium: '300ms',
    /** 350ms */
    long: '350ms',
  },
  out: {
    /** 200ms */
    short: '200ms',
    /** 250ms */
    medium: '250ms',
    /** 300ms */
    long: '300ms',
  },
  inOut: {
    /** 250ms */
    short: '250ms',
    /** 300ms */
    medium: '300ms',
    /** 350ms */
    long: '350ms',
  },
};

export const transitions = {
  base: `all ${timings.out.short} ${easing.out}`,
  active: `all ${timings.inOut.short} ${easing.inOut}`,
} as const;

export const brand = {
  background: colors.black100,
  danger: colors.red400,
  dangerActive: colors.red500,
  dangerAlt: colors.red350,
  dangerFocus: colors.red100,
  dark: colors.black200,
  darkAlt: colors.gray900,
  darkActive: colors.black100,
  darkFocus: colors.black100,
  darkest: colors.black,
  darkTransparent: transparentize('0.7', colors.black200),
  error: colors.red400,
  light: colors.gray50,
  lightActive: colors.white,
  lightAlt: colors.white,
  lightFocus: colors.white,
  lightTransparent: transparentize('0.9', colors.gray50),
  mildTransparent: transparentize('0.5', colors.black),
  primary: colors.yellow500,
  primaryActive: colors.yellow300,
  primaryAlt: colors.yellow400,
  primaryFocus: colors.yellow50,
  primaryTransparent: transparentize('0.9', colors.yellow500),
  secondary: colors.yellow300,
  secondaryActive: colors.yellow200,
  secondaryAlt: colors.yellow300,
  secondaryFocus: colors.yellow50,
  subtle: colors.gray800,
  subtleActive: colors.gray500,
  subtleAlt: colors.gray600,
  subtleFocus: colors.gray100,
  success: colors.green500,
  successActive: colors.green300,
  successAlt: colors.green400,
  successFocus: colors.green100,
  tertiary: colors.purple500,
  tertiaryActive: colors.purple300,
  tertiaryAlt: colors.purple400,
  warning: colors.orange400,
  overlay: transparentize('0.3', colors.black),
} as const;

export const brandRGB = convertColorsToRGB(brand);

export const keyframes = {
  contentShow: {
    '0%': {
      opacity: '0',
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0%)',
    },
  },
  explode: {
    '0%': { transform: 'rotate(0deg)' },
    '25%': { transform: 'rotate(-3deg) rotateY(10deg)' },
    '50%': { transform: 'rotate(0deg)' },
    '75%': { transform: 'rotate(3deg) rotateY(-10deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
  dialogShow: {
    '0%': {
      opacity: '0',
      transform: 'translate(-50%, -48%) scale(0.96)',
    },
    '100%': {
      opacity: '1',
      transform: 'translate(-50%, -50%) scale(1)',
    },
  },
  overlayShow: {
    '0%': {
      opacity: '0',
    },
    '100%': {
      opacity: '1',
    },
  },
  shimmer: {
    '0%': { backgroundPosition: '-200% 0' },
    '100%': { backgroundPosition: '100% 0' },
  },
  slideUpAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateY(1rem)`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  slideRightAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateX(-1rem)`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
  },
  slideDownAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateY(-1rem)`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  slideLeftAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateX(1rem)`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
  },
  slideUpAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateY(-1rem)`,
    },
  },
  slideRightAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateX(1rem)`,
    },
  },
  slideDownAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateY(1rem)`,
    },
  },
  slideLeftAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateX(-1rem)`,
    },
  },
  spin: {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
} as const;

export const zIndex = {
  z001: '1',
  z002: '2',
  z003: '3',
  z004: '4',
  z010: '10',
  z100: '100',
  z105: '105',
  z110: '110',
  z200: '200', // Main nav sidebar
  z250: '250', // Saved Items Panel
  z270: '270', // Dialog Overlay
  z280: '280', // Dialogs, Select, DropdownMenu
  z300: '300', // tooltip
  z999: '999', // toast
} as const;
