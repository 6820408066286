import { vars } from '@lib/theme';
import { PowerKeyword } from '../power-keywords';
import { Collaborator } from './collaborator-api';

export type ProjectPage = {
  current: 1;
  next: null;
  previous: null;
};

export type ProjectsAPI = {
  totalRecords: number;
  results: ProjectsResponseItem[];
  page: ProjectPage;
};

export const PROJECT_STATUS = {
  IDEA: 'idea',
  IN_DEVELOPMENT: 'in_development',
  IN_POST_PRODUCTION: 'in_post_production',
  READY_TO_PUBLISH: 'ready_to_publish',
  PUBLISHED: 'published',
} as const;

export type StatusItem = {
  label: string;
  value: ProjectStatusType;
  color: ColorCodes;
};

export const PROJECT_STATUS_ITEMS: StatusItem[] = [
  {
    label: 'Idea',
    value: PROJECT_STATUS.IDEA,
    color: vars.colors.gray50,
  },
  {
    label: 'In Development',
    value: PROJECT_STATUS.IN_DEVELOPMENT,
    color: vars.colors.orange400,
  },
  {
    label: 'In Post-Production',
    value: PROJECT_STATUS.IN_POST_PRODUCTION,
    color: vars.colors.yellow300,
  },
  {
    label: 'Ready to Publish',
    value: PROJECT_STATUS.READY_TO_PUBLISH,
    color: vars.colors.green400,
  },
  {
    label: 'Published',
    value: PROJECT_STATUS.PUBLISHED,
    color: vars.colors.purple400,
  },
];

export type ColorCodes = (typeof vars.colors)[keyof typeof vars.colors];

export type ProjectStatusType =
  (typeof PROJECT_STATUS)[keyof typeof PROJECT_STATUS];

export type ProjectStatusKey = keyof typeof PROJECT_STATUS;

export type ProjectStepsStarted = {
  outline?: boolean;
  concept?: boolean;
  packaging?: boolean;
};

export const PROJECT_ELEMENT = {
  CONCEPT: 'concept',
  TITLE: 'title',
  THUMBNAIL: 'thumbnail',
} as const;

export type ProjectElementType =
  (typeof PROJECT_ELEMENT)[keyof typeof PROJECT_ELEMENT];

export type ElementOption = {
  id: string; // uuid
  content: string;
  description?: string;
  seed?: string;
  powerKeywords?: PowerKeyword[];
};

export type TextOptions = {
  primary?: string; // primary option's uuid
  options?: ElementOption[];
};

export type ThumbnailOptions = {
  primary?: string;
  options?: ElementOption[];
  prompt?: string;
};

export type ThumbnailInput = {
  channelId: string;
  orgId: string;
  projectId: string;
  image?: string;
  imageFileName?: string;
  imageMimeType?: string;
};

export type PresignedS3UploadRes = {
  cloudfrontURL: string;
  url: string;
  headers: {
    [key: string]: string;
  };
  fields: {
    [key: string]: string;
  };
};

export type ThumbnailRes = {
  cloudfrontURL: string;
  url: string;
};

export const PROJECT_META = {
  NAME: 'name',
  STATUS: 'status',
  IS_PUBLIC: 'isPublic',
  TYPE: 'type',
  DURATION: 'duration',
  PUBLISH_DATE: 'publishDate',
  TAGS: 'tags',
  SPONSORS: 'sponsors',
  YOUTUBE_URL: 'youtubeURL',
  FRAME_IO_URL: 'frameIoURL',
  AVERAGE_VIEW_DURATION: 'averageViewDuration',
  OUTLIER_INDEX: 'outlierIndex',
} as const;

export type ProjectNameInspiration = {
  primaryThumbnail?: string;
  primaryConcept?: string;
  primaryTitle?: string;
};

export type ProjectMeta = {
  name?: string;
  averageViewDuration?: string | undefined;
  duration?: string | undefined;
  outlierIndex?: string | undefined;
  /**
   * for use by state only - isn't persisted to the b/e.
   * undefined for existing projects, initially false for new ones
   */
  nameSetOrGenerated?: boolean;
  /**
   * for use by state only - isn't persisted to the b/e
   */
  nameGenInspiration?: ProjectNameInspiration;
  status: ProjectStatusType;
  isPublic: boolean;
  publishDate?: string | undefined;
  tags?: string[];
  sponsors?: string[];
  type?: string;
  youtubeURL?: string;
  frameIoURL?: string;
};

export type VideoProject = {
  meta: ProjectMeta;
  title?: TextOptions;
  concept?: TextOptions;
  thumbnail?: ThumbnailOptions;
  outline?: boolean;
};

export type ProjectDocument = {
  videoProject: VideoProject;
  id: string;
};

export type ProjectInput = {
  project: VideoProject;
  channelId: string;
  orgId: string;
};

export type ProjectCrudInput = {
  // why are isPublic and status required fields in the meta object?
  // rather than changing that type, just overriding their spec.
  project: Omit<ProjectMeta, 'isPublic' | 'status'> & {
    isPublic?: boolean;
    status?: ProjectStatusType;
    title?: string;
    concept?: string;
    thumbnail?: string;
  };
  projectId: string;
  channelId: string;
  orgId: string;
};

export type ProjectDeleteReq = {
  id: string;
  channelId: string;
  orgId: string;
};

export type ProjectDuplicateReq = {
  id: string;
  channelId: string;
  orgId: string;
};

export type VideoProjectWithRoom = {
  createdAt: string;
  defaultAccesses: string[];
  groupsAccesses: string[][];
  id: string;
  lastConnectionAt?: string;
  metadata: string[];
  type: string;
  usersAccesses: string[][];
  videoProjectId: string;
};

export type VideoProjectSource =
  | 'brainstorming'
  | 'idea_bank'
  | 'ideas_for_you'
  | 'navigation'
  | 'projects_page'
  | 'system'
  | 'user';

// TODO update to match changes planned for backend
export type ProjectsResponseItem = {
  averageViewDuration?: string;
  channelUCID: string;
  collaborators: Collaborator[];
  concept?: string;
  createdAt: Date;
  createdBy: string;
  duration?: string;
  id: string;
  isPublic: boolean;
  name?: string;
  outlierIndex?: string;
  outline?: boolean;
  publishDate?: Date;
  source?: VideoProjectSource;
  sponsors: string[];
  status: ProjectStatusType;
  tags: string[];
  thumbnail?: string;
  title?: string;
  type?: string;
  updatedAt: Date;
  videoProjectId: string;
  views?: string;
};

export type DuplicateProjectResponse = {
  channelUCID: string;
  collaborators: Collaborator[];
  createdBy: string;
  id: string;
  isPublic: boolean;
  name?: string;
  status: ProjectStatusType;
  thumbnail?: string;
  title?: string;
};
