import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { useFeatureGate } from '@statsig/react-bindings';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import { hasUserSeenTour } from '../utils';

export const ProjectsTour = () => {
  const { setIsOpen, setMeta } = useTour();
  const location = useLocation();
  const { value: projectsTourGate } = useFeatureGate(
    STATSIG_FEATURE_FLAGS.STUDIO_PROJECTS_FTUE_TOUR
  );
  // temporarily disabling the projects tour when Idea Bank is enabled
  // because the tour needs to be rewritten to make any sense.
  const { value: ideaBankTourGate } = useFeatureGate(
    STATSIG_FEATURE_FLAGS.STUDIO_IDEA_BANK
  );
  const hasSeenTour = hasUserSeenTour('projects');

  useEffect(() => {
    if (ideaBankTourGate || !projectsTourGate || hasSeenTour) {
      return;
    }

    // the projects index page is the only place this tour should kickoff
    const pathname = location.pathname;
    const projectsStepsActive =
      pathname.includes('/projects') &&
      !pathname.includes('/projects/new-project');

    if (projectsStepsActive) {
      setIsOpen(false);
      setMeta?.('projects');
      setTimeout(() => setIsOpen(true), 1000);
    }
  }, [hasSeenTour, location.pathname]);

  return null;
};
