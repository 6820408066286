/**
 * Safely encodes a string into Base64 format, handling non-Latin characters.
 * The input string is first encoded as a UTF-8 byte array, then Base64 encoded.
 *
 * @param {string} input - The string to encode into Base64.
 * @returns {string} The Base64-encoded version of the input string.
 */
export const safeBtoa = (input: string): string => {
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(input);
  let binaryString = '';
  for (let i = 0; i < byteArray.length; i++) {
    binaryString += String.fromCharCode(byteArray[i]);
  }
  return btoa(binaryString);
};

/**
 * Safely decodes a Base64-encoded string back to its original form, handling UTF-8 characters.
 *
 * @param {string} encoded - The Base64-encoded string.
 * @returns {string} The decoded string.
 */
export const safeAtob = (encoded: string): string => {
  try {
    const binaryString = atob(encoded);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(byteArray);
  } catch (error) {
    throw new Error('Invalid Base64 string');
  }
};
