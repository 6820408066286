import { api } from '@studio/lib';

interface DeleteSavedItemInput {
  channelUcid: string;
  orgId: string;
  id: string;
}

export const deleteSavedItem = async (
  input: DeleteSavedItemInput
): Promise<DeleteSavedItemInput> => {
  await api.bowser.delete(
    `/api/orgs/${input.orgId}/channels/${input.channelUcid}/saved-items/${input.id}`
  );
  return input;
};

export default { deleteSavedItem };
