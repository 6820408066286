import { Navigate, Outlet } from 'react-router-dom';
import { UserProfileOrganizationUserRole } from '@studio/types';
import { useRoleManager } from '../hooks/use-role-manager';

type Props = {
  allowedRoles: UserProfileOrganizationUserRole[];
  redirectRoute: string;
};

export const OrganizationRoleRouteGuard = ({
  allowedRoles,
  redirectRoute,
}: Props) => {
  const { isAllowableByOrganization } = useRoleManager();

  return isAllowableByOrganization(allowedRoles) ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  );
};
