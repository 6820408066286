import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '@studio/lib/heap';
import { Banner } from '@lib/ui';
import * as EVENTS from '../heap.constants';
import { usePersistentBannerStore } from '../store';
import { PersistentMessageBannerProps } from '../types';

export function PersistentMessageBanner(props: PersistentMessageBannerProps) {
  const { t } = useTranslation();
  const { description, variant, icon, action } = props;
  const { popBanner } = usePersistentBannerStore();

  useEffect(() => {
    trackEvent(EVENTS.PERSISTENT_BANNER_APPEAR);
    return () => {
      trackEvent(EVENTS.PERSISTENT_BANNER_DISAPPEAR);
    };
  }, []);

  return (
    <Banner.Provider duration={Infinity} swipeDirection="right">
      <Banner.Root open={true} onOpenChange={popBanner}>
        <Banner.Content>
          <Banner.Description>
            {icon ? (
              <Banner.Adornment variant={variant}>{icon}</Banner.Adornment>
            ) : null}
            <span>{description}</span>
          </Banner.Description>
          <Banner.Actions>
            <Banner.Close
              onClick={() => trackEvent(EVENTS.PERSISTENT_BANNER_DISMISS_CLICK)}
            >
              {t('Dismiss')}
            </Banner.Close>
            {action ? (
              <Banner.Action
                variant={variant}
                altText={action.altText}
                onClick={action.callback}
              >
                {action.text}
              </Banner.Action>
            ) : null}
          </Banner.Actions>
        </Banner.Content>
      </Banner.Root>
      <Banner.Viewport />
    </Banner.Provider>
  );
}
