import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tiptap/react';
import { useAutoFocus } from '@studio/hooks';
import { isValidUrl, isValidYouTubeUrl } from '@studio/utils';
import {
  Button,
  Form,
  Icons,
  Label,
  Popover,
  Separator,
  TextInput,
  Toast,
} from '@lib/ui';
import * as Styles from './toolbar.css';
import { ToolbarIconButton } from '.';

type Props = {
  editor: Editor;
};

async function isImageLoadable(url: string) {
  return new Promise((resolve) => {
    try {
      const image = new Image();
      image.onload = () => {
        resolve(true);
      };
      image.onerror = () => {
        resolve(false);
      };
      image.src = url;
    } catch (error) {
      resolve(false);
    }
  });
}

export const ToolbarMedia = forwardRef<HTMLDivElement, Props>(
  ({ editor }, ref) => {
    const { t } = useTranslation();
    const { toast } = Toast.useToast();
    const [isImagePopoverOpen, setImagePopoverOpen] = useState(false);
    const [isYouTubePopoverOpen, setYouTubePopoverOpen] = useState(false);

    async function addImage(url: string) {
      if (!url.length) {
        return;
      }

      setImagePopoverOpen(false);

      const isLoadable = await isImageLoadable(url);
      if (!isLoadable) {
        toast({ message: t('Invalid image URL'), variant: 'alert' });
        return;
      }

      const { state, view } = editor;
      const { schema, tr } = state;
      const pos = state.selection.from;

      // Insert a paragraph node at the new position
      const transaction = tr.insert(pos + 1, schema.nodes.paragraph.create());
      view.dispatch(transaction);

      // Move the focus to the new paragraph
      editor
        .chain()
        .setImage({ src: url })
        .setTextSelection(pos + 4)
        .focus('end')
        .run();
    }

    function addYouTube(url: string) {
      if (!url.length) {
        return;
      }

      setYouTubePopoverOpen(false);

      const { state, view } = editor;
      const { schema, tr } = state;
      const pos = state.selection.from;

      // Insert a paragraph node at the new position
      const transaction = tr.insert(pos + 1, schema.nodes.paragraph.create());
      view.dispatch(transaction);

      // Move the focus to the new paragraph
      editor
        .chain()
        .setYoutubeVideo({ src: url })
        .setTextSelection(pos + 4)
        .focus('end')
        .run();
    }

    return (
      <>
        <Popover.Root
          open={isImagePopoverOpen}
          onOpenChange={setImagePopoverOpen}
        >
          <Popover.Trigger asChild>
            <ToolbarIconButton
              disabled={!editor.can().chain().setImage({ src: '' }).run()}
              data-active={editor.isActive('image') ? 'is-active' : undefined}
              label={t('Image')}
              onClick={() => setImagePopoverOpen(true)}
              icon={<Icons.ImageIcon aria-hidden />}
            />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align="start" className={Styles.popoverContent}>
              <Popover.Close className={Styles.popoverClose} />
              <MediaPopover variant="image" onSubmit={addImage} />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
        <Separator variant="light" direction="vertical" />
        <Popover.Root
          open={isYouTubePopoverOpen}
          onOpenChange={setYouTubePopoverOpen}
        >
          <Popover.Trigger asChild>
            <ToolbarIconButton
              disabled={!editor.can().chain().setImage({ src: '' }).run()}
              data-active={editor.isActive('youtube') ? 'is-active' : undefined}
              label={t('YouTube')}
              onClick={() => setYouTubePopoverOpen(true)}
              icon={<Icons.YouTubeIcon aria-hidden />}
            />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align="start" className={Styles.popoverContent}>
              <Popover.Close className={Styles.popoverClose} />
              <MediaPopover variant="youtube" onSubmit={addYouTube} />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </>
    );
  }
);

type MediaPopoverProps = {
  variant: 'image' | 'youtube';
  onSubmit: (url: string) => void;
};

function MediaPopover({ variant, onSubmit }: MediaPopoverProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const { ref: inputRef } = useAutoFocus();
  const isImage = variant === 'image';
  const isYouTube = variant === 'youtube';

  const handleChange = (value: string) => {
    // Replace white space
    const newValue = value.replace(/\s/, '');

    // Set value
    setValue(newValue);

    // Validate value and enable/disable submit button
    const isValid = isImage
      ? isValidUrl(value, { pathname: true })
      : isValidYouTubeUrl(value);
    setIsDisabled(!isValid);
  };

  const titleText = isImage ? t('Add an Image') : t('Embed Youtube Video');
  const formText = isImage ? t('Add image URL') : t('Add YouTube URL');

  return (
    <>
      <Label>{titleText}</Label>
      <Form.Root
        className={Styles.toolbarPopoverForm}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit(value);
        }}
      >
        <Form.Field name={variant}>
          <Form.Label className={Styles.toolbarPopoverLabel} htmlFor="media">
            {formText}
          </Form.Label>
          <Form.Control asChild>
            <div className={Styles.toolbarPopoverBar}>
              <TextInput.Root variant="light">
                <TextInput.Input
                  ref={inputRef}
                  placeholder={isYouTube ? 'https://www.youtube.com/' : ''}
                  value={value}
                  onChange={handleChange}
                />
              </TextInput.Root>
            </div>
          </Form.Control>
        </Form.Field>
        <div className={Styles.toolbarPopoverButtons}>
          <Form.Submit
            disabled={isDisabled}
            className={Styles.toolbarPopoverButton}
            asChild
          >
            <Button size="sm">{t('Add')}</Button>
          </Form.Submit>
        </div>
      </Form.Root>
    </>
  );
}
