import { useState, useEffect, useCallback } from 'react';
import { useProfileQuery } from '@studio/hooks';
import { api } from '@studio/lib';

type Customer = {
  id: string;
  object: string;
  address: null;
  balance: number;
  created: number;
  currency: string | null;
  default_source: null;
  delinquent: boolean;
  description: string | null;
  discount: null;
  email: string;
  invoice_prefix: string;
  invoice_settings: {
    custom_fields: null;
    default_payment_method: null;
    footer: null;
    rendering_options: null;
  };
  livemode: boolean;
  metadata: {
    env: string;
    orgId: string;
  };
  name: string;
  next_invoice_sequence: number;
  phone: null;
  preferred_locales: string[];
  shipping: null;
  tax_exempt: string;
  test_clock: null;
};

type BillingPortalConfiguration = {
  id: string;
  object: string;
  active: boolean;
  application: string | null;
  business_profile: {
    headline?: string | null;
    privacy_policy_url: string;
    terms_of_service_url: string;
  };
  created: number;
  default_return_url?: string | null;
  features?: {
    customer_update?: {
      allowed_updates: string[];
      enabled: boolean;
    };
    invoice_history?: {
      enabled: boolean;
    };
    payment_method_update?: {
      enabled: boolean;
    };
    subscription_cancel?: {
      cancellation_reason?: {
        enabled: boolean;
        options: string[];
      };
      enabled: boolean;
      mode?: string;
      proration_behavior?: string;
    };
    subscription_pause?: {
      enabled: boolean;
    };
    subscription_update?: {
      default_allowed_updates: string[];
      enabled: boolean;
      proration_behavior?: string;
    };
  };
  is_default: boolean;
  livemode: boolean;
  login_page?: {
    enabled: boolean;
    url?: string | null;
  };
  metadata: Record<string, unknown>;
  updated: number;
};

type CreateBillingSessionResponse = {
  data: {
    sessionUrl: string;
  };
  status: number;
  message: string;
};

export const useCreateStripeBillingSession = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [session, setSession] = useState<CreateBillingSessionResponse | null>(
    null
  );
  const { data: profileData, isLoading: isProfileLoading } = useProfileQuery();

  const createSession = useCallback(
    async (redirectUrl: string = '/auth/connect-channel') => {
      if (!profileData || !profileData.email) {
        throw new Error(
          'Could not retrieve user email. Could not create Stripe billing session'
        );
      }

      setIsLoading(true);
      setError(null);

      try {
        const customers = await api.bowser.get<Customer[]>(
          `/api/stripe/customer?email=${encodeURIComponent(profileData.email)}`
        );

        if (customers.length === 0) {
          throw new Error('No customers found with the provided email');
        }

        const [{ id: customerId }] = customers;

        const { id: configurationId } =
          await api.bowser.post<BillingPortalConfiguration>(
            `/api/stripe/billing-configuration`,
            {
              headers: { 'Content-Type': 'application/json' },
              data: {
                business_profile: {
                  privacy_policy_url: 'https://spotter.la/privacy-policy',
                  terms_of_service_url: 'https://spotter.la/terms-of-service',
                },
                features: {
                  customer_update: {
                    enabled: false,
                  },
                  invoice_history: { enabled: true },
                  subscription_cancel: {
                    enabled: true,
                    cancellation_reason: {
                      enabled: true,
                      // Stripe cancellation reasons options:
                      // - too_expensive
                      // - missing_features
                      // - switched_service
                      // - unused
                      // - customer_service
                      // - too_complex
                      // - low_quality
                      // - other
                      options: [
                        'too_expensive', // It's too expensive
                        'missing_features', // I need more features
                        'switched_service', // I found an alternative
                        'unused', // I no longer need it
                        'other', // Other reason
                      ],
                    },
                  },
                  payment_method_update: {
                    enabled: true, // Enable customers to update their payment methods, including credit cards.
                  },
                },
                default_return_url: `${window.location.origin}${redirectUrl}`,
              },
            }
          );

        const checkoutSession =
          await api.bowser.post<CreateBillingSessionResponse>(
            `/api/stripe/billing-session`,
            {
              headers: { 'Content-Type': 'application/json' },
              data: {
                customerId,
                configurationId,
              },
            }
          );

        setSession(checkoutSession);
        return checkoutSession;
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : 'An unexpected error occurred';
        setError(errorMessage);
        console.error(errorMessage);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [profileData]
  );

  useEffect(() => {
    if (!isProfileLoading && !profileData) {
      setError('User data is not available');
    }
  }, [isProfileLoading, profileData]);

  return {
    createSession,
    error,
    isLoading: isLoading || isProfileLoading,
    session,
  };
};
