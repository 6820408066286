import { ReactNode, useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useFeatureGate } from '@statsig/react-bindings';
import { useInspirationStore } from '@studio/features/brainstorm/stores';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { PANEL_CONTROL_INPUTS } from '@studio/features/projects/constants';
import { useNewProjectId } from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useActiveOrganizationId } from '@studio/stores';
import { PROJECT_ELEMENT, VIDEO_GUIDE } from '@lib/types';
import { BrainstormButton } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import { AugmentedStepType, StepContentBody } from '../components/step-content';
import * as ComponentStyles from '../components/styles.css';
import { getContentComponent, makeSelector } from '../utils';
import { TOUR_IDS } from './constants';

export const useBrainstormFTUESteps = (): AugmentedStepType[] => {
  const { setTopic } = useInspirationStore();
  const navigate = useNavigate();
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const { getId } = useNewProjectId();
  const { value: isPanelLayoutSwapEnabled } = useFeatureGate(
    STATSIG_FEATURE_FLAGS.PROJECT_DETAIL_PANEL_LAYOUT
  );

  const getInputsStep = useCallback(() => {
    const step: Partial<AugmentedStepType> = {
      onBefore() {
        navigate({
          pathname: `/app/${orgId}/${channelUcid}/projects/new-project`,
          search: createSearchParams({
            [ProjectQueryParams.ELEMENT]: PROJECT_ELEMENT.TITLE,
            [ProjectQueryParams.BRAINSTORM]: 'true',
            [ProjectQueryParams.NEW_PROJECT_ID]: `${getId()}`,
            [ProjectQueryParams.PANEL_CONTROL]: PANEL_CONTROL_INPUTS,
          }).toString(),
        });
      },
      type: 'step',
    };

    step.selector = makeSelector(
      isPanelLayoutSwapEnabled
        ? TOUR_IDS.PDP_INSPIRATION_BAR
        : TOUR_IDS.PDP_OPTIONS_PANEL
    );

    step.body = isPanelLayoutSwapEnabled
      ? 'After generating ideas personalized to your channel, you can further steer Brainstorm by adding more about your audience and additional details to refine your ideas.'
      : "We've already generated initial ideas for you, but you can use the <strong>Inputs Panel</strong> to override the topic or add constraints to guide the AI.\n\nLet's try adding a topic.";

    return step as AugmentedStepType;
  }, [isPanelLayoutSwapEnabled]);

  const getTopicsStep = useCallback(() => {
    const step: Partial<AugmentedStepType> = {
      onBefore() {
        setTopic(isPanelLayoutSwapEnabled ? 'Rocket to Mars' : 'Rocket Ship');
      },
      type: 'step',
    };

    step.selector = makeSelector(
      isPanelLayoutSwapEnabled
        ? TOUR_IDS.PDP_INSPIRATION_BAR_INPUT
        : TOUR_IDS.PDP_TOPIC_INPUT
    );

    step.body = isPanelLayoutSwapEnabled
      ? 'Here we\'ve added the idea "Rocket to Mars". Click Go to see updated titles.'
      : 'Here we\'ve added the topic "Rocket Ship".';

    return step as AugmentedStepType;
  }, [isPanelLayoutSwapEnabled]);

  const getThumbnailStep = useCallback(() => {
    const step: Partial<AugmentedStepType> = {
      onBefore(currentPane) {
        // prettier-ignore
        const selector = `${currentPane} ${makeSelector(TOUR_IDS.PDP_KEEP_BUTTON)}`;
        // prettier-ignore
        const keepButton = document.querySelector(selector) as HTMLButtonElement;
        keepButton?.click();

        if (isPanelLayoutSwapEnabled) {
          setTimeout(() => {
            // remove radix's focus guard and pointer events styles so the inspo bar's types
            // menu can remain open for this step of the tour
            document.querySelector('[data-radix-focus-guard=""]')?.remove();
            const body = document.querySelector('body');
            if (body) {
              body.style.pointerEvents = 'all';
            }
          }, 500);
        }
      },
      type: 'step',
    };

    step.selector = makeSelector(
      isPanelLayoutSwapEnabled
        ? TOUR_IDS.PDP_INSPIRATION_BAR_TYPE_MENU
        : TOUR_IDS.PDP_IDEATION_TYPES
    );

    step.body = isPanelLayoutSwapEnabled
      ? "Now that we've chosen a title, we can generate a matching thumbnail idea or hook! Let's click Thumbnails to switch modes then click Go again."
      : "Now that we've chosen a title, we can generate a matching thumbnail idea or hook! Let's click Thumbnail to switch modes.";

    if (!isPanelLayoutSwapEnabled) {
      step.onAfterLoad = (steps, setSteps) => {
        const updatedSteps = [...steps];
        const selector = makeSelector(TOUR_IDS.PDP_IDEATION_TYPES);
        updatedSteps[6].selector = selector;
        updatedSteps[6].mutationObservables = [selector];
        updatedSteps[6].resizeObservables = [selector];
        setSteps(updatedSteps);
      };
    }

    return step as AugmentedStepType;
  }, [isPanelLayoutSwapEnabled]);

  const stepsSansContent: Omit<AugmentedStepType, 'content'>[] = [
    {
      selector: '#root',
      title: 'Welcome to Spotter Studio!',
      body: 'Begin your creative journey by brainstorming your first video project and unlocking instant inspiration, tailored to you.',
      type: 'takeover',
      videoSrc: '/assets/ftue-brainstorm.mp4',
      delay: 0,
    },
    {
      selector: makeSelector(TOUR_IDS.DASHBOARD_BRAINSTORM),
      body: "To help steer your Idea Brainstorm, enter a topic that inspired you, select one of the suggested topics or click Surprise me.\n\nFor now, let's try <strong>Surprise me</strong>.",
      type: 'step',
      delay: 0,
    },
    getInputsStep(),
    getTopicsStep(),
    // this step's omitted when new pdp layout is enabled
    {
      selector: makeSelector(TOUR_IDS.PDP_GENERATE_BUTTON),
      body: 'Click <strong>Generate</strong> to see the updated results.',
      type: 'step',
      delay: 0,
    },
    {
      onBefore() {
        const generateButton = document.getElementById(
          isPanelLayoutSwapEnabled
            ? TOUR_IDS.PDP_INSPIRATION_BAR_GO_BUTTON
            : TOUR_IDS.PDP_GENERATE_BUTTON
        );
        if (generateButton) {
          generateButton.click();
        }
      },
      onAfterLoad: (steps, setSteps, currentPane) => {
        const updatedSteps = [...steps];
        // because of the omitted step above
        const stepIndex = isPanelLayoutSwapEnabled ? 4 : 5;

        // TODO: this is broken

        const selector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_CARD_CONTAINER
        )}`;
        updatedSteps[stepIndex].selector = selector;
        updatedSteps[stepIndex].mutationObservables = [selector];
        updatedSteps[stepIndex].resizeObservables = [selector];
        setSteps(updatedSteps);
      },
      selector: makeSelector(TOUR_IDS.PDP_IDEATION_CARD_CONTAINER),
      mutationObservables: [makeSelector(TOUR_IDS.PDP_IDEATION_CONTAINER)],
      resizeObservables: [makeSelector(TOUR_IDS.PDP_IDEATION_CONTAINER)],
      position: 'left',
      body: "Here are your new video title ideas! They are clearly about rocket ships, but they're all ideas unique you your brand and channel. You can click ::ideation-keep-label:: on any you like to start your new video project.\n\nLet's keep the first one for now.",
      type: 'step',
      delay: 800,
    },
    getThumbnailStep(),
    {
      onBefore(currentPane) {
        // for new pdp layout, click on "Thumbnails" in the inspiration bar types menu
        if (isPanelLayoutSwapEnabled) {
          // prettier-ignore
          const menu = document.querySelector(makeSelector(TOUR_IDS.PDP_INSPIRATION_BAR_TYPE_MENU))
          // prettier-ignore
          const thumbnailsItem = menu?.querySelector('[aria-label="Thumbnails"]') as HTMLDivElement;
          thumbnailsItem?.click();

          // delay allows the params store time to register the effect of clicking the menu item
          setTimeout(() => {
            // prettier-ignore
            const generateButton = document.getElementById(TOUR_IDS.PDP_INSPIRATION_BAR_GO_BUTTON);
            generateButton?.click();
          }, 200);

          return;
        }

        const typesSelector = makeSelector(TOUR_IDS.PDP_IDEATION_TYPES);
        const thumbnailButtonSelector = `${typesSelector} button:first-child`;
        const hooksButtonSelector = `${typesSelector} button:last-child`;
        // prettier-ignore
        const thumbnailButton = document.querySelector(thumbnailButtonSelector) as HTMLButtonElement;
        // prettier-ignore
        const hooksButton = document.querySelector(hooksButtonSelector) as HTMLButtonElement;

        if (
          thumbnailButton &&
          thumbnailButton.innerText.includes('Thumbnail')
        ) {
          thumbnailButton.click();
        } else if (hooksButton) {
          hooksButton.click();
        }
      },
      onAfterLoad(steps, setSteps, currentPane) {
        const updatedSteps = [...steps];

        const selector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_CARD
        )}`;
        const stepIndex = isPanelLayoutSwapEnabled ? 6 : 7;
        updatedSteps[stepIndex].selector = selector;
        updatedSteps[stepIndex].mutationObservables = [selector];
        updatedSteps[stepIndex].resizeObservables = [selector];

        setSteps(updatedSteps);
      },
      selector: makeSelector(TOUR_IDS.PDP_IDEATION_CARD),
      mutationObservables: [makeSelector(TOUR_IDS.PDP_IDEATION_CARD)],
      resizeObservables: [makeSelector(TOUR_IDS.PDP_IDEATION_CARD)],
      // extra padding to reveal more of the yellow brainstorm btn
      padding: 18,
      position: 'left',
      body: "We think these thumbnails might pair well with your title. But these are just starting points. You can refine and explore until you're truly inspired by clicking ::brainstorm-icon:: on any generated idea. Let's give it a try on this one.",
      type: 'step',
      delay: 800,
    },
    {
      onBefore(currentPane) {
        const menuContainerSelector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_SPIDER_MENU_CONTAINER
        )}`;

        const getSpiderMenuButton = () => {
          // prettier-ignore
          return document.querySelector(`${menuContainerSelector} button`) as HTMLButtonElement;
        };

        const btn = getSpiderMenuButton();

        if (!btn) {
          // since ideation cards may still be generating, retry until the
          // first card's brainstorm menu btn does exist
          const interval = setInterval(() => {
            const btn = getSpiderMenuButton();

            if (btn) {
              btn.click();
              // reactour listens to resize events and reevaluates the step's selector target.
              // hacky but works and we're not creating a whole event emitter system for this.
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
              }, 100);
              clearInterval(interval);
            }
          }, 500);
        } else {
          btn.click();
        }
      },
      onAfterLoad(steps, setSteps, currentPane) {
        const updatedSteps = [...steps];
        const menuContainerSelector = `${currentPane} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_SPIDER_MENU_CONTAINER
        )}`;
        const selector = `${menuContainerSelector} ${makeSelector(
          TOUR_IDS.PDP_IDEATION_SPIDER_MENU
        )}`;
        const stepIndex = isPanelLayoutSwapEnabled ? 7 : 8;
        updatedSteps[stepIndex].selector = selector;
        updatedSteps[stepIndex].mutationObservables = [selector];
        updatedSteps[stepIndex].resizeObservables = [selector];
        setSteps(updatedSteps);
      },
      selector: makeSelector(TOUR_IDS.PDP_IDEATION_SPIDER_MENU),
      mutationObservables: [
        makeSelector(TOUR_IDS.PDP_IDEATION_SPIDER_MENU_CONTAINER),
      ],
      body: "On thumbnails, you can generate variations, change an expression or the composition of the shot, or make it your own via 'This, but'.\n\nWhen you're done with the tour, make sure to explore all available options across titles, hooks and thumbnails.",
      type: 'step',
      delay: 0,
    },
    {
      selector: '#root',
      body: "Great job! You're now equipped with the essentials of how to brainstorm new video ideas in Spotter Studio.\n\nAfter the tour head back to your dashboard by clicking 'Home' on your left to start your next brainstorm.",
      type: 'takeover',
      delay: 0,
    },
  ];

  let steps: AugmentedStepType[] = stepsSansContent.map((step) => {
    const tokens = Object.values(replacementTokens);
    const Content = getContentComponent(step);

    if (
      typeof step.body === 'string' &&
      tokens.some((token) => (step.body as string).includes(token))
    ) {
      step.body = replaceTokens(step.body, {
        // TODO: is it no longer desired to have the last step link to the Feature Guide video?
        openModal: () => {},
        // TODO: don't we need to use the a/b test value?
        keepLabel: 'keep',
      });
    }

    return {
      ...step,
      content: () => <Content {...step} />,
      navDotAriaLabel: '',
    };
  });

  // hacky - fortunately will be removing this layout swap feature gate soon
  if (isPanelLayoutSwapEnabled) {
    steps = steps.filter((_, index) => index !== 4);
  }

  return steps;
};

const replacementTokens = {
  icon: '::brainstorm-icon::',
  guide: '::brainstorm-feature-guide::',
  keep: '::ideation-keep-label::',
};

const replaceTokens = (
  text: ReactNode,
  helpers: {
    openModal: (id: string) => void;
    keepLabel: string;
  }
) => {
  if (!text) {
    return '';
  }

  let replaced = <span>{text}</span>;
  let split = text.toString().split(replacementTokens.icon);

  if (split.length > 1) {
    replaced = (
      <>
        <span>{split[0]}</span>
        {/* the brainstorm button uses some absolute positioned pseudo elements we need to wrangle */}
        <span style={{ position: 'relative', display: 'inline-block' }}>
          <BrainstormButton isInteractable={false} />
        </span>
        <span>{split[1]}</span>
      </>
    );
  }

  split = text.toString().split(replacementTokens.guide);

  if (split.length > 1) {
    replaced = (
      <>
        <span>{split[0]}</span>
        <StepContentBody
          onClick={() => helpers.openModal(VIDEO_GUIDE)}
          className={ComponentStyles.StepLink}
        >
          Brainstorm feature guide
        </StepContentBody>
        <span>{split[1]}</span>
      </>
    );
  }

  // because "Keep" is presently under an A/B test where it may read "Select"
  split = text.toString().split(replacementTokens.keep);

  if (split.length > 1) {
    replaced = (
      <>
        <span>{split[0]}</span>
        <strong>{helpers.keepLabel}</strong>
        <span>{split[1]}</span>
      </>
    );
  }

  return replaced;
};
