/**
 * Unique base keys for each hook that utilizes useQuery.
 */
export const USE_QUERY_KEYS = {
  CHECKOUT_SESSION_QUERY_KEY: 'checkoutSession',
  CREATE_PROJECT: 'create-project',
  FETCH_CAST_MEMBERS_KEY: 'fetch-cast-members',
  FETCH_ALL_PROJECTS_KEY: 'fetch-all-projects',
  FETCH_CHANNEL_STATES_LIST_INFINITE_KEY: 'fetch-channel-stats-list-infinite',
  FETCH_FAVORITE_CHANNELS_KEY: 'favorite-Channels',
  FETCH_IDEA_WORKSHOP_ELEMENTS_KEY: 'idea-workshop',
  FETCH_POWER_KEYWORDS_KEY: 'fetch-power-keywords',
  FETCH_PREVIEW_AAW_VIDEOS: 'fetch-preview-aaw-videos',
  FETCH_PROJECTS_KEY: 'projects',
  FETCH_PROJECTS_INFINITE_KEY: 'projects-infinite',
  FETCH_SAVED_BACKUP_ITEMS_KEY: 'saved-backup-items',
  FETCH_SAVED_OUTLIER_ITEMS_KEY: 'saved-outlier-items',
  FETCH_SEARCHED_CREATORS_INFINITE_KEY: 'searched-creators-infinite',
  FETCH_SUBSCRIBER_COUNT_KEY: 'fetch-subscriber-count',
  FETCH_STRIPE_PLAN_BY_ID_KEY: 'stripe-plan-by-id',
  FETCH_STRIPE_PLANS_KEY: 'stripe-plans',
  FETCH_UNPUBLISHED_PROJECTS_KEY: 'fetch-unpublished-projects',
  IDEA_WALL_KEY: 'idea-wall',
  PROFILE_QUERY_KEY: 'user-profile',
  TEAM_SETTINGS_QUERY_KEY: 'team-settings',
  TEAM_QUERY_KEY: 'team',
  TREND_QUERY_KEY: 'dashboard-ts-videos',
  UPDATE_PROJECT_KEY: 'update-project',
  VIDEO_SEARCH_QUERY_KEY: 'search-video-by-videoId',
  FETCH_CHANNEL_BY_SUBSCRIBERS: 'fetch-channel-by-subscribers',
};
