import {
  forwardRef,
  useRef,
  type ChangeEvent,
  type ForwardedRef,
  type InputHTMLAttributes,
  type PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icons, IconButton } from '@lib/ui';
import { HEAP_REPLAY } from '../../constants';
import * as Styles from './text-input-multiline.css';
import * as SharedStyles from '../text-input-inline/text-input-inline.css';

/**
 * --------------------------------------------------------
 * TextInputMultiline Root
 * --------------------------------------------------------
 */
export type TextInputMultilineRootProps = PropsWithChildren & {
  className?: string;

  /**
   * Puts the input itself into an "errored" visual state
   */
  hasError?: boolean;

  /**
   * On click callback
   */
  onClick?: () => void;

  /**
   * On click callback
   */
  onBlur?: () => void;

  /**
   * On focus callback
   */
  onFocus?: () => void;
};

export const Root = forwardRef(
  (props: TextInputMultilineRootProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, className, hasError, onClick, onFocus, onBlur } = props;

    return (
      <div
        className={clsx(
          SharedStyles.root,
          { [SharedStyles.error]: hasError },
          className
        )}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

Root.displayName = 'TextInputMultilineRoot';

/**
 * --------------------------------------------------------
 * TextInputMultiline Input
 * --------------------------------------------------------
 */
export type TextInputMultilineInputProps = PropsWithChildren &
  Styles.TextInputMultilineVariants &
  Omit<
    InputHTMLAttributes<HTMLTextAreaElement>,
    'onBlur' | 'onChange' | 'value' | 'size'
  > & {
    id?: string;
    /**
     * Boolean to display the adornment on mouseover
     */
    includeAdornment?: boolean;

    /**
     * Boolean to always display the adornment
     */
    alwaysShowAdornment?: boolean;

    /**
     * Input value
     */
    value?: string;

    /**
     * Callback to get the input's value as a string on `blur`.
     *
     * Optionally provides the entire event just in case you need it.
     */
    onBlur?: (value: string) => void;

    /**
     * Callback to get the input's value as a string on `change`.
     *
     * Optionally provides the entire event just in case you need it.
     */
    onChange: (value: string) => void;

    /**
     * Affects top and bottom padding
     */
    size?: 'xs' | 'sm' | 'md' | 'lg';

    /**
     * Number of rows to display initially
     * TODO: this should be a prop of InputHTMLAttributes<HTMLTextAreaElement> I would've thought...
     */
    rows?: number;
  };

export const Input = (props: TextInputMultilineInputProps) => {
  const {
    children,
    className,
    disabled,
    id,
    includeAdornment = true,
    alwaysShowAdornment = false,
    onBlur,
    onChange,
    size = 'xs',
    value = '',
    rows = 1,
    ...inputProps
  } = props;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    if (onChange) {
      onChange(value);
    }
  };

  const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    if (onBlur) {
      onBlur(value);
    }
  };

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleAutoHeight = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (containerRef?.current) {
      containerRef.current.dataset.replicatedValue = event.target.value;
    }
  };

  return (
    <div
      className={clsx(
        Styles.textInputMultilineVariants({ size, autoHeight: true }),
        className
      )}
      ref={containerRef}
    >
      <textarea
        data-element="text-input"
        ref={inputRef}
        className={clsx(SharedStyles.input, className, {
          [HEAP_REPLAY]: inputProps.type !== 'password',
        })}
        disabled={disabled}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        onInput={handleAutoHeight}
        rows={rows}
        type={inputProps.type}
        {...inputProps}
        value={value}
      />
      {includeAdornment ? (
        <span
          className={clsx({
            [Styles.adornment]: true,
            alwaysShowAdornment,
          })}
        >
          <IconButton
            aria-hidden
            label={t('Click to edit title')}
            size="xs"
            onClick={handleIconClick}
            icon={<Icons.EditIcon aria-hidden />}
          />
        </span>
      ) : null}
      <span className={SharedStyles.sizer}>{value}</span>
    </div>
  );
};

Input.displayName = 'TextInputMultilineInput';
