import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGateValue } from '@statsig/react-bindings';
import clsx from 'clsx';
import { useStore } from 'zustand';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import {
  useCreateSavedBackupItemMutation,
  useDeleteSavedBackupItemMutation,
  useFetchSavedBackupItems,
} from '@studio/features/saved-items/hooks';
import { useCopyToClipboard } from '@studio/hooks';
import { ProjectStoreContext } from '@studio/providers';
import { useActiveOrganizationId, useModalStore } from '@studio/stores';
import { downloadImage, truncateContent } from '@studio/utils';
import {
  ElementOption,
  NewSavedItem,
  NewThumbnailSavedItem,
  PROJECT_ELEMENT,
  ProjectElementType,
  SavedItemsType,
} from '@lib/types';
import {
  ConfirmDialog,
  Icons,
  IdeationElement,
  MenuAction,
  Thumbnail,
  Toast,
} from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import * as Styles from './options-panel-content.css';
import { useOptionsPanelAnalytics } from './use-options-panel-analytics';

type OptionsPanelItem = {
  id: string;
  isPrimary?: boolean;
  option: ElementOption;
  type: ProjectElementType;
  disabled?: boolean;
};

export function OptionsPanelItem(props: OptionsPanelItem) {
  const { id, isPrimary, option, type, disabled } = props;

  const { content, description } = option;

  const { t } = useTranslation();

  const [copyToClipboard] = useCopyToClipboard();

  const { toast } = Toast.useToast();

  const { modals, closeModal, openModal } = useModalStore();

  const { projectStore } = useContext(ProjectStoreContext);

  const { deleteElement, setElementPrimary } = useStore(projectStore);

  const { data: savedItemsResponse } = useFetchSavedBackupItems();

  const orgId = useActiveOrganizationId();
  const channelUcid = useActiveChannelUcid();

  const backupType: SavedItemsType =
    SavedItemsType[
      type.toUpperCase() as unknown as keyof typeof SavedItemsType
    ];

  const isThumbnail = type === PROJECT_ELEMENT.THUMBNAIL;

  const variant = isThumbnail ? 'subtle' : type;

  const ACTIONS = MenuAction.MENU_ACTION;

  const itemRef = useRef<HTMLDivElement>(null);

  const savedItemObject = {
    type: backupType,
    assetId: id,
    [backupType]: content,
  } as NewSavedItem;

  if (isThumbnail) {
    (savedItemObject as NewThumbnailSavedItem).description = description;
  }

  const { mutate: createSavedItem } = useCreateSavedBackupItemMutation();

  const { mutate: deleteSavedItem } = useDeleteSavedBackupItemMutation();
  const savedItem = savedItemsResponse?.results?.find(
    ({ assetId }) => assetId === id
  );

  const focusCard = () => {
    if (itemRef.current) {
      itemRef.current.focus();
    }
  };

  const selectItem = (id: string) => {
    setElementPrimary(type, id);
  };

  const removeSavedItem = () => {
    if (savedItem) {
      deleteSavedItem({ id: savedItem.id });
    }
  };

  const deleteItem = (id: string) => {
    deleteElement(type, id);
    closeModal(id);
  };

  const handleCopy = (content: string) => {
    copyToClipboard(content);
    toast({
      message: t('{{type}} copied!', {
        type: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
      }),
      duration: 2000,
    });
  };

  const handleOpenChange = (isOpen: boolean, id: string) => {
    isOpen ? openModal(id) : closeModal(id);
  };

  const actions = [
    {
      type: savedItem ? ACTIONS.DELETE : ACTIONS.SAVE,
      label: savedItem ? t('Remove from Saved Items') : t('Save for Later'),
      icon: savedItem ? (
        <Icons.HeartFilledIcon className={Styles.savedColor} aria-hidden />
      ) : (
        ((<Icons.HeartIcon aria-hidden />) as JSX.Element)
      ),
      handler: savedItem
        ? removeSavedItem
        : () => createSavedItem(savedItemObject),
    },
  ];

  if (isThumbnail) {
    actions.push({
      type: ACTIONS.DOWNLOAD,
      label: t('Download'),
      icon: <Icons.DownloadIcon aria-hidden />,
      handler: () => downloadImage(content, id),
    });
  }

  if (!isThumbnail) {
    actions.push({
      type: ACTIONS.COPY,
      label: t('Copy'),
      icon: <Icons.CopyIcon aria-hidden />,
      handler: () => handleCopy(content),
    });
  }

  actions.push({
    type: ACTIONS.DELETE,
    label: t('Delete'),
    icon: <Icons.DeleteIcon aria-hidden />,
    handler: () => openModal(id),
  });

  const hasPowerKeywordsAccess = useGateValue(
    STATSIG_FEATURE_FLAGS.STUDIO_SHOW_BRAINSTORM_POWER_KEYWORDS
  );

  const powerKeywords =
    hasPowerKeywordsAccess && !isThumbnail ? option.powerKeywords : undefined;

  const { onPowerKeywordClick, onPowerKeywordHover } = useOptionsPanelAnalytics(
    projectStore.getState().id
  );

  if (!option) {
    return;
  }

  return (
    <div className={`${Styles.item} element-${type}`} key={id}>
      <IdeationElement.Root id={type} ref={itemRef}>
        <IdeationElement.IdeationCard
          bottomLeftCorner={!isThumbnail}
          variant={variant}
        >
          <IdeationElement.IdeationCardHeader className={Styles.itemHeader}>
            {isThumbnail ? (
              <Thumbnail.ImageViewer
                className={Styles.viewerContainer}
                icon={<Icons.ExpandIcon aria-label={t('Expand thumbnail')} />}
                src={content}
              />
            ) : null}
            {!disabled ? (
              <IdeationElement.Actions
                actions={actions}
                type={type}
                onMenuClose={focusCard}
                disabled={!id}
                side="bottom"
                sideOffset={2}
                alignOffset={-2}
              />
            ) : null}
          </IdeationElement.IdeationCardHeader>
          <IdeationElement.IdeationCardContent
            variant={variant}
            powerKeywords={powerKeywords}
            textContent={content}
            readOnly
            className={clsx(Styles.elementCard, {
              [Styles.elementCardContent]: !isThumbnail,
            })}
            onPowerKeywordHover={(keyword) =>
              onPowerKeywordHover(keyword, content)
            }
            onPowerKeywordClick={(keyword) => {
              onPowerKeywordClick(keyword, content);
              window.open(
                // Adding spaces around the keyword to ensure the outliers search matches full words rather than partial words
                `/app/${orgId}/${channelUcid}/outliers?search= ${keyword.content} &searchBy=titles`,
                '_blank'
              );
            }}
          >
            {isThumbnail ? (
              <Thumbnail.Root id={id}>
                <Thumbnail.Image src={content} />
              </Thumbnail.Root>
            ) : (
              <div className={Styles.elementCardContent}>{content}</div>
            )}
          </IdeationElement.IdeationCardContent>
          {isPrimary || disabled ? null : (
            <button
              className={Styles.makePrimary}
              onClick={() => selectItem(id)}
            >
              <span>{t('Make primary')}</span>
            </button>
          )}
        </IdeationElement.IdeationCard>
      </IdeationElement.Root>
      <ConfirmDialog
        key={id}
        cancelLabel={t('Cancel')}
        confirmLabel={t('Delete')}
        open={modals[id]}
        onCancel={() => closeModal(id)}
        onConfirm={() => deleteItem(id)}
        onOpenChange={(isOpen) => handleOpenChange(isOpen, id)}
        title={t(
          `Delete ${
            isThumbnail ? 'thumbnail' : `"${truncateContent(content, 24)}"`
          }?`
        )}
      >
        {t(
          `Are you sure you want to delete this ${type}? This action cannot be undone.`
        )}
      </ConfirmDialog>
    </div>
  );
}
