import { type ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { StatsigProvider, StatsigUser } from '@statsig/react-bindings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuthStore } from '@studio/features/auth';
import { useAutoTokenRefresh } from '@studio/hooks/use-auto-token-refresh';
import {
  beforeSend,
  VITE_APP_VERSION,
  VITE_DATADOG_APPLICATION_ID,
  VITE_DATADOG_CLIENT_TOKEN,
  VITE_DATADOG_ENV,
  VITE_DATADOG_SERVICE,
  VITE_DATADOG_SITE,
} from '@studio/lib/datadog';
import Intercom from '@studio/lib/intercom/intercom';
import { useStatsigClient } from '@studio/lib/statsig';
import { getCookieValue } from '@studio/utils';
import { isLocal } from '@studio/utils/env-utils';
import { Datadog } from '@lib/datadog';
import { Toast } from '@lib/ui';
import HeapAnalytics from '../lib/heap/analytics';
import { BrazeProvider } from './braze-provider';

export const queryClient = new QueryClient();

export function AppProviders({ children }: { children: ReactNode }) {
  const { email, id, isInternalUser, firstName, lastName } = useAuthStore(
    (store) => ({
      email: store.user?.email,
      id: store.user?.id,
      firstName: store.user?.firstName,
      lastName: store.user?.lastName,
      isInternalUser: store.isInternalUser(),
    })
  );

  const stableID = getCookieValue('statsigStableID') ?? undefined;
  const statsigUser: StatsigUser = {
    email,
    userID: email || '',
    custom: {
      isInternal: isInternalUser,
      spotterUserId: id,
      stableID,
      isLoggedIn: !!email,
    },
    customIDs: {
      stableID,
    },
  };

  const onGateEvaluation = (key: string, value: boolean) => {
    // Only add feature flags if DataDog is enabled
    if (datadogRum.getInternalContext()) {
      datadogRum.addFeatureFlagEvaluation(key, value);
    }
  };

  const { client: statsigClient, isLoading: isStatsigLoading } =
    useStatsigClient({
      initialUser: statsigUser,
      onGateEvaluation,
    });

  const datadogUserData = {
    email: email ?? '',
    name: `${firstName} ${lastName}`,
    id: id ?? '',
  };

  const datadogRumConfig = {
    applicationId: VITE_DATADOG_APPLICATION_ID,
    service: VITE_DATADOG_SERVICE,
    beforeSend,
  };

  useAutoTokenRefresh();

  // Ensure that Statsig is ready with latest values before rendering
  // https://docs.statsig.com/client/javascript-sdk/init-strategies#overview
  if (isStatsigLoading || !statsigClient) {
    return null; // or a loading screen...
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <StatsigProvider client={statsigClient}>
          <BrazeProvider>
            <Toast.ToastProvider>{children}</Toast.ToastProvider>
          </BrazeProvider>
        </StatsigProvider>
      </QueryClientProvider>
      <Intercom />
      <HeapAnalytics />
      <Datadog
        clientToken={VITE_DATADOG_CLIENT_TOKEN}
        isEnabled={!isLocal()}
        site={VITE_DATADOG_SITE}
        env={VITE_DATADOG_ENV}
        version={VITE_APP_VERSION}
        rumInitConfig={datadogRumConfig}
        tracingUrls={import.meta.env.VITE_BOWSER_URL}
        userData={datadogUserData}
      />
    </>
  );
}
