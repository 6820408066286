import React, {
  PropsWithChildren,
  ReactNode,
  useRef,
  forwardRef,
  HTMLAttributes,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ProjectElementType } from '@lib/types';
import { DropdownMenu, IconButton, Icons } from '@lib/ui';
import * as Styles from './card.css';

/**
 * Card Root
 */

export type CardProps = PropsWithChildren &
  Styles.CardVariants & {
    className?: string;
    id?: string;
    onClick?: () => void;
  };

export const Root = ({
  children,
  id,
  className,
  bottomLeftCorner = false,
  bottomRightCorner = false,
  topLeftCorner = false,
  topRightCorner = false,
  variant,
  onClick,
}: CardProps) => {
  return (
    <div
      id={id}
      className={clsx(
        'card',
        Styles.cardVariants({
          bottomLeftCorner,
          bottomRightCorner,
          topLeftCorner,
          topRightCorner,
          variant,
        }),
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

Root.displayName = 'CardRoot';

/**
 * Header
 */
export type HeaderProps = PropsWithChildren & {
  className?: string;
};

export const Header = ({ className, children, ...props }: HeaderProps) => {
  return (
    <div {...props} className={clsx(Styles.cardHeader, className)}>
      {children}
    </div>
  );
};

Header.displayName = 'CardHeader';

/**
 * Content
 */
export type ContentProps = PropsWithChildren &
  HTMLAttributes<HTMLSpanElement> & {
    className?: string;
  };

export const Content = forwardRef<HTMLSpanElement, ContentProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <span ref={ref} className={clsx(Styles.content, className)} {...props}>
        {children}
      </span>
    );
  }
);

Content.displayName = 'CardContent';

/**
 * Actions
 */

type ActionItem = {
  icon?: ReactNode;
  handler: () => void;
  label: string;
  type?: string;
};

type ActionsProps = {
  actions?: ActionItem[];
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  onMenuClose?: () => void;
  noIndicator?: boolean;
  align?: DropdownMenu.ContentProps['align'];
  alignOffset?: DropdownMenu.ContentProps['alignOffset'];
  side?: DropdownMenu.ContentProps['side'];
  sideOffset?: DropdownMenu.ContentProps['sideOffset'];
  type?: ProjectElementType;
  variant?: 'dark' | 'light' | 'subtle';
};

export const Actions = (props: ActionsProps) => {
  const { t } = useTranslation();
  const menuRef = useRef<HTMLDivElement>(null);

  const {
    actions,
    align = 'end',
    alignOffset = 4,
    className,
    noIndicator,
    onMenuClose,
    side = 'right',
    sideOffset = 3,
    variant = 'dark',
  } = props;

  const handleCloseAutoFocus = (event: Event) => {
    if (onMenuClose) {
      event.preventDefault();
      onMenuClose();
    }
  };

  return (
    <div ref={menuRef} className={`${Styles.actions} ${className}`}>
      <DropdownMenu.Root {...props}>
        <DropdownMenu.Trigger asChild>
          <IconButton
            variant={variant}
            radii="pill"
            label={t('Open menu')}
            icon={<Icons.DotsVerticalIcon aria-hidden />}
            size="xs"
            className={Styles.menuTrigger}
            disabled={props.disabled}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal container={menuRef.current}>
          <DropdownMenu.Content
            className={Styles.actionsContent}
            align={align}
            alignOffset={alignOffset}
            avoidCollisions={false}
            side={side}
            sideOffset={sideOffset}
            variant="subtle"
            onCloseAutoFocus={handleCloseAutoFocus}
          >
            {actions?.map((item, index) => {
              return (
                <DropdownMenu.Item
                  align="start"
                  key={`${item.type}-${index}`}
                  onSelect={item.handler}
                >
                  {item?.icon} {item.label}
                </DropdownMenu.Item>
              );
            })}
            {noIndicator ? null : <DropdownMenu.Arrow />}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

Actions.displayName = 'CardActions';

/**
 * Keyword
 */
export type KeywordProps = PropsWithChildren & {
  className?: string;
};

export const Keyword = ({ children, className }: KeywordProps) => {
  return <span className={clsx(Styles.keyword, className)}>{children}</span>;
};

Keyword.displayName = 'CardKeyword';

export type LabelProps = PropsWithChildren & {
  className?: string;
};

export const Label = ({ children, className }: LabelProps) => {
  return <label className={clsx(Styles.label, className)}>{children}</label>;
};
