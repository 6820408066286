import { api } from '@studio/lib';
import { SavedItemsResponseData } from '@lib/types';
import { BackupSavedItem } from '@lib/types';

export const fetchSavedBackupItems = async ({
  channelUcid,
  orgId,
}: {
  channelUcid: string;
  orgId: string;
}): Promise<SavedItemsResponseData<BackupSavedItem>> => {
  return api.bowser.get(
    `/api/orgs/${orgId}/channels/${channelUcid}/saved-items?type__in=title,concept,thumbnail`
  );
};

export default { fetchSavedBackupItems };
