import { type Params } from 'react-router-dom';

/**
 * There are similar helpers in outliers/helpers but I made a couple changes so
 * the dirty filter count is based on the params passed
 * rather than the set of defaults. This is needed because the count
 * used in Projects is only on the couple of filters in the More overflow.
 */

/**
 * Return only params that pertains to filters.
 */
export const omitSearchParams = (params: Params) => {
  const { layout, search, searchBy, count, ...rest } = params;
  return rest;
};

/**
 * Get the count of filters that have been changed from defaults.
 */
export const getDirtyFiltersCount = (
  params: Params,
  defaultParams: Params
): number => {
  let count = 0;
  const p1 = omitSearchParams(params);

  for (const key of Object.keys(params)) {
    if (p1[key] !== undefined && p1[key] !== defaultParams[key]) {
      count++;
    }
  }

  return count;
};
