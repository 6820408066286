/**
 * Returns an empty string if the input number is 1 (or -1), otherwise returns the letter "s".
 * If no number is provided, it treats the input as 0.
 * Negative numbers are converted to positive before evaluation.
 *
 * @param {number | string} [count=0] - The number to evaluate, can be a number or a string representation of a number.
 * @returns {string} An empty string if the input is 1 (or -1), otherwise "s".
 */
export const pluralize = (count: number | string = 0): string => {
  let numericCount = typeof count === 'string' ? parseFloat(count) : count;
  numericCount = Math.abs(numericCount); // Convert negative numbers to positive
  return numericCount === 1 ? '' : 's';
};
