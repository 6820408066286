/**
 * Truncates a string to a specified maximum length and adds an ellipsis ("...") if it exceeds that length.
 *
 * @param {string} content - The string to be truncated.
 * @param {number} maxLength - The maximum length of the string before truncation.
 * @returns {string} The truncated string with an ellipsis if it exceeds the specified length, otherwise the original string.
 */
export const truncateContent = (content: string, maxLength: number) => {
  return content.length > maxLength
    ? `${content.slice(0, maxLength)}...`
    : content;
};
