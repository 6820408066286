import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { RangeFilter } from '@studio/components';
import { ParamsObject, useParamStore } from '@studio/stores';
import { Form } from '@lib/ui';
import { NumberParser } from '@lib/utils';
import { ParamId } from '../types';

function serializeValue(value: number) {
  if (!value) {
    return '';
  }
  const n = Intl.NumberFormat('en-us').format(value);
  return n;
}

function deserializeValue(value: string) {
  if (!value) {
    return NaN;
  }
  const parser = new NumberParser('en');
  const s = parser.parse(value);
  return Number(s);
}

export type Props = {
  minId: ParamId;
  maxId: ParamId;
  eventNames: {
    opened: string;
    changed: string;
  };
};

export function OutliersFilterInput({ eventNames, minId, maxId }: Props) {
  const { params, setParamsTracked, removeParamTracked } = useParamStore();
  const { t } = useTranslation();

  const handleSetParams = (value: ParamsObject) => {
    setParamsTracked(eventNames.changed, value);
  };

  const removeAndTrackParams = (value: string) => {
    removeParamTracked(eventNames.changed, value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    for (const [key, value] of formData.entries()) {
      if (!value && !params[key]) {
        return;
      }
      const numericValue = deserializeValue(value as string);
      setParamsTracked(eventNames.changed, {
        [key]: numericValue ? numericValue.toString() : '',
      });
    }
  };

  return (
    <Form.Root onSubmit={handleSubmit}>
      <RangeFilter
        min={0}
        minId={minId}
        minPlaceholder="0"
        max={Infinity}
        maxId={maxId}
        maxPlaceholder={t('Any')}
        serializeValue={serializeValue}
        deserializeValue={deserializeValue}
        params={params}
        setParams={handleSetParams}
        removeParam={removeAndTrackParams}
      />
      <Form.Submit />
    </Form.Root>
  );
}
