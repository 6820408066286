import { createClient } from '@liveblocks/client';
import { createRoomContext } from '@liveblocks/react';
import { useLiveBlockStore } from '@studio/features/projects/stores';
import { useChannelStore } from '../features/channel-select';
import { api } from '../lib';

export const client = createClient({
  backgroundKeepAliveTimeout: 60 * 60 * 1000,
  authEndpoint: async (room?: string) => {
    const { setLiveBlockConnectionError } = useLiveBlockStore.getState();
    try {
      const channelUcids = await useChannelStore
        .getState()
        .getAvailableChannelUcids();

      const res = await api.bowser.post<{ token: string }>(
        `/api/liveblocks/identify`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            channelUcids,
          }),
        }
      );
      return res;
    } catch (err) {
      setLiveBlockConnectionError('Failed to authenticate with Liveblocks');
      throw new Error(`Failed to authenticate with Liveblocks ${err}`);
    }
  },
});

type Presence = {
  cursor: { x: number; y: number } | null;
  // ...
};

// Optionally, Storage represents the shared document that persists in the
// Room, even after all users leave. Fields under Storage typically are
// LiveList, LiveMap, LiveObject instances, for which updates are
// automatically persisted and synced to all connected clients.
type Storage = {
  // author: LiveObject<{ firstName: string, lastName: string }>,
  // ...
};

// Optionally, UserMeta represents static/readonly metadata on each user, as
// provided by your own custom auth back end (if used). Useful for data that
// will not change during a session, like a user's name or avatar.
type UserMeta = {
  id: string; // Accessible through `user.id`
  info: {
    first: string;
    last: string;
  }; // Accessible through `user.info`
};

// Optionally, the type of custom events broadcast and listened to in this
// room. Use a union for multiple events. Must be JSON-serializable.
type RoomEvent = {
  // type: "NOTIFICATION",
  // ...
};

export const {
  suspense: {
    RoomProvider,
    useRoom,
    useMyPresence,
    useUpdateMyPresence,
    useSelf,
    useOthers,
    useOthersMapped,
    useOthersConnectionIds,
    useOther,
    useBroadcastEvent,
    useEventListener,
    useErrorListener,
    useStorage,
    useBatch,
    useHistory,
    useUndo,
    useRedo,
    useCanUndo,
    useCanRedo,
    useMutation,
    useStatus,
    useLostConnectionListener,
  },
} = createRoomContext<Presence, Storage, UserMeta, RoomEvent>(client);
