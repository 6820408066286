import { ReactNode, useEffect, useState } from 'react';
import { ParamsObject } from '@studio/stores';
import { Flex, ToggleGroup } from '@lib/ui';
import { Icons } from '@lib/ui';
import * as Styles from './filters.css';
import { RangeFilter } from './range-filter';
import { ParamId } from './types';

type ToggleFilterProps = {
  min: number;
  maxId: ParamId;
  maxLabel: string;
  maxPlaceholder?: string;
  max: number;
  minId: ParamId;
  minLabel: string;
  params: ParamsObject;
  setParams: (value: ParamsObject) => void;
  removeParam: (paramId: string) => void;
  minPlaceholder?: string;
  serializeValue?: (value: number) => string;
  deserializeValue?: (value: string) => number;
};

type ToggleOption = {
  children: ReactNode;
  value: string;
  min: string;
  max: string;
};

function conversionToToggleValue(
  boundary: 'min' | 'max',
  value: string | undefined,
  options: ToggleOption[]
) {
  if (value) {
    const matchingItem = options.find((item) => {
      return item[boundary] === value;
    });
    return matchingItem?.value;
  }
  return undefined;
}

function createRangeLabel(direction: string, label: string) {
  const IconComponent =
    direction === 'left' ? Icons.ChevronLeftIcon : Icons.ChevronRightIcon;
  return (
    <>
      <IconComponent aria-hidden /> {label}
    </>
  );
}

const options = [
  {
    children: createRangeLabel('left', '100K'),
    value: 'under100k',
    min: '',
    max: '100000',
  },
  {
    children: createRangeLabel('left', '1M'),
    value: 'under1m',
    min: '',
    max: '1000000',
  },
  {
    children: createRangeLabel('left', '5M'),
    value: 'under5m',
    min: '',
    max: '5000000',
  },
  {
    children: createRangeLabel('right', '1M'),
    value: 'over1m',
    min: '1000000',
    max: '',
  },
  {
    children: createRangeLabel('right', '10M'),
    value: 'over10m',
    min: '10000000',
    max: '',
  },
];

export const ToggleFilter = (props: ToggleFilterProps) => {
  const {
    max,
    maxId,
    maxPlaceholder,
    min,
    minId,
    minPlaceholder,
    params,
    setParams,
    removeParam,
    serializeValue = (v) => v.toString(),
    deserializeValue = (v) => Number(v),
  } = props;

  const minToggleValue = conversionToToggleValue('min', params[minId], options);
  const maxToggleValue = conversionToToggleValue('max', params[maxId], options);
  const [toggleValue, setToggleValue] = useState(
    minToggleValue || maxToggleValue
  );

  const handleToggleChange = (updatedValue: string) => {
    const findMappingValue = options.find(
      (item) => item.value === updatedValue
    );

    if (findMappingValue) {
      setParams({
        [minId]: findMappingValue.min,
        [maxId]: findMappingValue.max,
      });
    }

    setToggleValue(updatedValue);
  };

  useEffect(() => {
    /*
     * Once there are neither min or max values we need to clear our the toggle selection.
     * This is an unfortunate side effect of how we are currently handling toggling
     * the accelerators in these components.
     */
    if (!params[minId] && !params[maxId]) {
      setToggleValue('');
    }
  }, [params]);

  return (
    <Flex flexDirection="column" gap="1rem">
      <RangeFilter
        min={min}
        minId={minId}
        minPlaceholder={minPlaceholder}
        max={max}
        maxId={maxId}
        maxPlaceholder={maxPlaceholder}
        serializeValue={serializeValue}
        deserializeValue={deserializeValue}
        params={params}
        setParams={setParams}
        removeParam={removeParam}
      />
      <div>
        <ToggleGroup.Root
          className={Styles.toggleGroup}
          type="single"
          size="sm"
          pill={true}
          variant="subtle"
          value={toggleValue}
          onValueChange={handleToggleChange}
        >
          {options.map((item) => (
            <ToggleGroup.Item key={item.value} id={item.value} {...item} />
          ))}
        </ToggleGroup.Root>
      </div>
    </Flex>
  );
};
