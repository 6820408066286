import { api } from '@studio/lib';
import { VideoProjectPostInput, VideoProjectPostResponse } from '@studio/types';

export const createProject = async ({
  input,
  createYDoc = true,
}: {
  input: VideoProjectPostInput;
  createYDoc?: boolean;
}): Promise<VideoProjectPostResponse> => {
  const { channelId, orgId, ...data } = input;

  return await api.bowser.post<VideoProjectPostResponse>(
    `/api/orgs/${orgId}/channels/${channelId}/video-projects?createYdoc=${createYDoc}`,
    {
      data,
      headers: {
        'content-type': 'application/json',
      },
    }
  );
};
