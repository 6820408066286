import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useProfileQuery } from '@studio/hooks';
import { useActiveOrganizationId } from '@studio/stores';
import { useOrganizationStore } from '@studio/stores';
import {
  UserProfileEntitlementRole,
  UserProfileOrganizationUserRole,
} from '@studio/types';

export const useRoleManager = () => {
  const { data: user } = useProfileQuery();
  const activeOrganizationId = useActiveOrganizationId();
  const activeChannelUcid = useActiveChannelUcid();
  const { getActiveOrganizationUser } = useOrganizationStore();

  const isAllowableByEntitlement = (
    allowedRoles: UserProfileEntitlementRole[]
  ) => {
    let isAllowed = false;

    const entitlement = user?.entitlements.find((entitlement) => {
      return (
        entitlement.organizationId === activeOrganizationId &&
        entitlement.channel.ucid === activeChannelUcid
      );
    });

    if (entitlement) {
      const hasAllowedRoles = allowedRoles.includes(
        entitlement.role as UserProfileEntitlementRole
      );
      if (hasAllowedRoles) {
        isAllowed = true;
      }
    }

    return isAllowed;
  };

  const isAllowableByOrganization = (
    allowedRoles: UserProfileOrganizationUserRole[]
  ) => {
    const orgRole = getActiveOrganizationUser()?.role;
    if (!orgRole) {
      return false;
    }

    return Boolean(allowedRoles.find((role) => role === orgRole));
  };

  return { isAllowableByEntitlement, isAllowableByOrganization };
};
