import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useActiveOrganizationId } from '@studio/stores';
import { SavedItemsResponseData } from '@lib/types';
import { Toast } from '@lib/ui';
import { deleteSavedItem } from '../requests/deleteSavedItem';

export function useDeleteSavedBackupItemMutation() {
  const { toast } = Toast.useToast();
  const queryClient = useQueryClient();
  const orgId = useActiveOrganizationId();
  const channelUcid = useActiveChannelUcid();

  return useMutation({
    mutationKey: ['delete-saved-item'],
    mutationFn: ({ id }: { id: string }) =>
      deleteSavedItem({ orgId, channelUcid, id }),
    onSuccess: (savedItem) => {
      queryClient.setQueryData(
        ['saved-backup-items', channelUcid, orgId],
        (oldSavedItems: SavedItemsResponseData) => ({
          ...oldSavedItems,
          totalRecords: (oldSavedItems.totalRecords || 0) + 1,
          results: (oldSavedItems.results || []).filter(
            ({ id }) => id !== savedItem.id
          ),
        })
      );
    },
    onError: () =>
      toast({
        message: t('Failed to delete item. Please try again later.'),
      }),
  });
}
