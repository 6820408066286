import { forwardRef } from 'react';
import { Props as IdeaBankCardProps } from './idea-bank-card';

/**
 * This is a placeholder for a drag n drop sortable idea bank card.
 * Coming in Idea Bank v1.
 */
export const IdeaBankCardSortableDummy = forwardRef(
  ({ id, ...props }: IdeaBankCardProps, ref) => {
    return (
      <div {...props} ref={ref as React.RefObject<HTMLDivElement>}>
        {/* for debugging only - this could be changed to look just like the real card but not be an accordion item */}
        {id}
      </div>
    );
  }
);
