import { useQuery } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { useActiveOrganizationId } from '@studio/stores';
import { fetchSavedBackupItems } from '../requests/fetchSavedBackupItems';

export const useFetchSavedBackupItems = () => {
  const orgId = useActiveOrganizationId();
  const channelUcid = useActiveChannelUcid();

  return useQuery({
    queryKey: [USE_QUERY_KEYS.FETCH_SAVED_BACKUP_ITEMS_KEY, channelUcid, orgId],
    queryFn: () => fetchSavedBackupItems({ channelUcid, orgId }),
  });
};
