import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import * as Styles from './badge.css';

/**
 * Badge
 */

export type BadgeProps = PropsWithChildren &
  Styles.BadgeVariants & {
    className?: string;
  };

export const Badge = ({
  children,
  fill = 'solid',
  iconOnly = false,
  pill = false,
  size = 'large',
  variant = 'primary',
  weight = 'bold',
  ...props
}: BadgeProps) => {
  return (
    <div
      className={clsx(
        Styles.badgeVariants({ fill, iconOnly, pill, size, variant, weight }),
        props.className
      )}
    >
      {children}
    </div>
  );
};
