import { Trans, useTranslation } from 'react-i18next';
import { AllowFilter } from '@studio/components';
import { useParamStore } from '@studio/stores';
import { PARAM_KEYS, SPONSORED } from '../../../../constants';

export const sponsorFilterItems = [
  {
    id: 'all',
    label: 'All',
    value: SPONSORED.ALL,
  },
  {
    id: 'yes',
    label: 'Yes',
    value: SPONSORED.YES,
  },
  {
    id: 'no',
    label: 'No',
    value: SPONSORED.NO,
  },
];

export function SponsorFilterInput() {
  const { t } = useTranslation();
  const { params, setParams, removeParam } = useParamStore();

  const value =
    params[PARAM_KEYS.SPONSORS_EXISTS] ?? sponsorFilterItems[0].value;

  const onChange = (param: { [key: string]: string }) => {
    const value = Object.values(param)[0];

    if (value === SPONSORED.ALL) {
      removeParam(PARAM_KEYS.SPONSORS_EXISTS);
      return;
    }

    setParams(param);
  };

  return (
    <Trans t={t}>
      <AllowFilter
        id={PARAM_KEYS.SPONSORS_EXISTS}
        value={value}
        setParams={onChange}
        items={sponsorFilterItems}
      />
    </Trans>
  );
}
