import { ForwardedRef, forwardRef } from 'react';
import { TextInput } from '@lib/ui';

type ControlledInputProps = {
  id: string;
  value: string;
  label: string;
  onUpdate: (value: string) => void;
  onChange: (value: string) => void;
};

export const ControlledInput = forwardRef(
  (props: ControlledInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { value, onUpdate, onChange } = props;

    return (
      <TextInput.Root size="lg">
        <TextInput.Input
          ref={ref}
          id={props.id}
          value={value}
          placeholder={props.label}
          onBlur={onUpdate}
          onChange={onChange}
        />
      </TextInput.Root>
    );
  }
);
