import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function HeartFilledIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2178_108966)">
        <path
          d="M4.97552 2C2.59673 2 1 3.86655 1 6.17164C1 8.78764 4.7696 11.9131 7.99907 14C11.2297 11.9131 14.9554 8.73745 14.9993 6.17164C15.0386 3.86655 13.4993 2 11.1205 2C10.1055 2 9.07593 2.52036 7.99907 3.56327C6.9222 2.52036 5.99049 2 4.97552 2Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2178_108966">
          <rect
            width="14"
            height="12"
            fill="transparent"
            transform="translate(1 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
