import { useCallback, useEffect, useRef, useState } from 'react';
import {
  StatsigClient,
  StatsigClientEvent,
  StatsigUser,
} from '@statsig/js-client';
import { getStatsigClient } from '../get-statsig-client';

export function useStatsigClient({
  initialUser,
  onGateEvaluation,
}: {
  initialUser?: StatsigUser;
  onGateEvaluation?: (key: string, value: boolean) => void;
} = {}): { isLoading: boolean; client: StatsigClient | null; error: unknown } {
  const [client, setClient] = useState<StatsigClient | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown | null>(null);
  const flags = useRef(new Map<string, boolean>());

  const getClientAsync = useCallback(async () => {
    try {
      const client = await getStatsigClient(initialUser);
      setClient(client);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to initialize Statsig client:', error);
      setError(error);
      setClient(null);
      setIsLoading(false);
    }
  }, [initialUser]);

  useEffect(() => {
    getClientAsync();
  }, [getClientAsync]);

  const handleGateEvaluation = useCallback(
    (event: StatsigClientEvent<'gate_evaluation'>) => {
      const { name, value } = event.gate;
      if (!flags.current.has(name)) {
        flags.current.set(name, value);
        onGateEvaluation?.(name, value);
      }
    },
    [flags, onGateEvaluation]
  );

  useEffect(() => {
    if (client?.loadingStatus === 'Ready') {
      client.on('gate_evaluation', handleGateEvaluation);
    }
    return () => {
      if (client) {
        client.off('gate_evaluation', handleGateEvaluation);
      }
    };
  }, [client, flags, handleGateEvaluation]);

  return { client, isLoading, error };
}
