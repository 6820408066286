export const colors = {
  amber: {
    '50': '#efdec0',
    '100': '#d7c2a0',
    '150': '#c3ac86',
    '200': '#af966b',
    '300': '#9b8051',
    '400': '#886a37',
    '500': '#74541d',
    '600': '#684b1a',
    '700': '#5c4317',
    '800': '#503a14',
    '850': '#443111',
    '900': '#38280e',
    '950': '#291e0a',
  },
  black: {
    '50': '#494846',
    '100': '#444341',
    '150': '#3f3f3d',
    '200': '#3b3a3a',
    '300': '#373636',
    '400': '#323232',
    '500': '#2e2e2e',
    '600': '#292929',
    '700': '#232323',
    '800': '#1e1e1e',
    '850': '#191919',
    '900': '#141414',
    '950': '#0d0d0d',
    '1000': '#000000',
  },
  blue: {
    '50': '#e6ecfc',
    '100': '#c3d4f7',
    '150': '#a8c2f3',
    '200': '#8cafee',
    '300': '#709cea',
    '400': '#5589e6',
    '500': '#3976e2',
    '600': '#3069cf',
    '700': '#275cbd',
    '800': '#1f50aa',
    '850': '#164397',
    '900': '#0d3684',
    '950': '#02266d',
  },
  dusk: {
    '50': '#494751',
    '100': '#43414b',
    '150': '#3e3c46',
    '200': '#393741',
    '300': '#35333d',
    '400': '#302e38',
    '500': '#2b2933',
    '600': '#26242e',
    '700': '#211f29',
    '800': '#1d1b25',
    '900': '#13111b',
    '950': '#0d0b15',
  },
  green: {
    '50': '#e6f7f2',
    '100': '#b9e2db',
    '150': '#96d0c9',
    '200': '#72bfb7',
    '300': '#4eaea5',
    '400': '#2b9d93',
    '500': '#078c81',
    '600': '#068176',
    '700': '#06766b',
    '800': '#056b60',
    '850': '#046056',
    '900': '#04554b',
    '950': '#03473d',
  },
  grey: {
    // TODO: Update to use system colors based on the design system
    '0': '#ffffff',
    '50': '#fafafa',
    '100': '#F8F8F8',
    '150': '#EEEEEE',
    '200': '#d9d9d9',
    '300': '#c7c5c0',
    '400': '#bdbdbd',
    '500': '#9e9e9e',
    '600': '#a09f9a',
    '700': '#908e8a',
    '800': '#7f7e7a', // #757575
    '850': '#6e6d6a', // #616161
    '900': '#5e5d5a', // #555555
    '950': '#494846', // #404040
  },
  lime: {
    '50': '#fcfae6',
    '100': '#f5f3bf',
    '150': '#f0eda0',
    '200': '#ebe781',
    '300': '#e6e261',
    '400': '#e0dc42',
    '500': '#dbd623',
    '600': '#c8c41e',
    '700': '#b5b118',
    '800': '#a29f13',
    '850': '#908c0d',
    '900': '#7d7a08',
    '950': '#656301',
  },
  melon: {
    '50': '#fff1de',
    '100': '#ffe2b4',
    '150': '#ffd692',
    '200': '#ffca70',
    '300': '#ffbf4f',
    '400': '#ffb32d',
    '500': '#ffa70b',
    '600': '#ea9809',
    '700': '#d58907',
    '800': '#c07906',
    '850': '#ab6a04',
    '900': '#955b02',
    '950': '#7b4800',
  },
  orange: {
    '400': '#FF7F37',
    '500': '#F76808',
  },
  pink: {
    '50': '#f7e7fd',
    '100': '#f1cafa',
    '150': '#ecb2f8',
    '200': '#e79bf6',
    '300': '#e283f3',
    '400': '#dd6cf1',
    '500': '#d854ef',
    '600': '#c44cd9',
    '700': '#b044c3',
    '800': '#9c3cae',
    '850': '#873498',
    '900': '#732c82',
    '950': '#5a2267',
  },
  purple: {
    '50': '#f7eeff',
    '100': '#dccef4',
    '150': '#c6b4eb',
    '200': '#b09be2',
    '300': '#9b81da',
    '400': '#8568d1',
    '500': '#6f4ec8',
    '600': '#6442b6',
    '700': '#5935a3',
    '800': '#4d2991',
    '850': '#421c7e',
    '900': '#37106c',
    '950': '#290055',
  },
  red: {
    '50': '#fee1e4',
    '100': '#fdc0c3',
    '150': '#fba6a8',
    '200': '#fa8c8e',
    '300': '#f97273',
    '400': '#f85859',
    '500': '#f73e3e',
    '600': '#df3434',
    '700': '#c72a2a',
    '800': '#af2021',
    '850': '#971617',
    '900': '#7f0c0d',
    '950': '#610001',
  },
  slate: {
    '50': '#d8c8e8',
    '100': '#b9b2c4',
    '150': '#a19aaf',
    '200': '#888199',
    '300': '#706984',
    '400': '#57506e',
    '500': '#382c66',
    '600': '#3a3451',
    '700': '#353048',
    '800': '#302c40',
    '850': '#2b2738',
    '900': '#26232f',
    '950': '#190d36',
  },
  success: {
    '200': '#6BE4AA',
    '300': '#3dd68c',
    '400': '#35B979',
    '500': '#30A46C',
    '600': '#2C8C5E',
    '700': '#246b4b',
    '800': '#1E5E41',
  },
  yellow: {
    '50': '#fff4e1',
    '100': '#ffebbe',
    '150': '#ffe3a2',
    '200': '#ffdc86',
    '300': '#ffd46b',
    '400': '#ffcd4f',
    '500': '#ffc533',
    '600': '#e8b32b',
    '700': '#d2a023',
    '800': '#bb8e1b',
    '850': '#a47b13',
    '900': '#8d690b',
    '950': '#715201',
  },
};
