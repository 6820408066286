import { type PropsWithChildren } from 'react';
import * as ToastPrimitives from '@radix-ui/react-toast';
import clsx from 'clsx';
import { Button } from '../button';
import * as ButtonStyles from '../button/button.css';
import * as Styles from './banner.css';
import { AdornmentVariants } from './banner.css';

type WithClassname = {
  className?: string;
};

/**
 * The Banner component is a variation of the Toast component with some heavily
 * opinionated styles and functionality. This should be used as persistent messaging
 * to the user when an event has taken place. We are using Toast here as the
 * underlying mechanisms due to meeting the same accessibility requirements.
 *
 * Banners are not ephemeral like Toasts. Only one banner should be active at
 * any time and remain on screen until the user has dismissed the banner or
 * the UI needs to spawn a new banner.
 */

export const Provider = ToastPrimitives.ToastProvider;
export const Viewport = ToastPrimitives.Viewport;

export const Root = (props: ToastPrimitives.ToastProps) => {
  return (
    <ToastPrimitives.Root
      {...props}
      onEscapeKeyDown={(e) => e.preventDefault()}
      className={clsx(Styles.root, props.className)}
    />
  );
};

export const Content = (props: WithClassname & PropsWithChildren) => {
  return <div {...props} className={clsx(Styles.content, props.className)} />;
};

export const Description = (props: ToastPrimitives.ToastDescriptionProps) => {
  return (
    <ToastPrimitives.Description
      {...props}
      className={clsx(Styles.description, props.className)}
    />
  );
};

export const Adornment = (
  props: AdornmentVariants & WithClassname & PropsWithChildren
) => {
  return (
    <div
      {...props}
      className={clsx(
        Styles.adornment({ variant: props.variant }),
        props.className
      )}
    />
  );
};

export const Actions = (props: WithClassname & PropsWithChildren) => {
  return <div {...props} className={clsx(Styles.actions, props.className)} />;
};

export const Action = (
  props: ButtonStyles.ButtonVariants & ToastPrimitives.ToastActionProps
) => {
  const { altText, ...rest } = props;
  return (
    <ToastPrimitives.Action altText={altText} asChild>
      <Button {...rest} variant={props.variant} fill="none" size="xs" />
    </ToastPrimitives.Action>
  );
};

export const Close = (props: ToastPrimitives.ToastCloseProps) => {
  return (
    <ToastPrimitives.Close asChild>
      <Button variant="subtle" fill="none" size="xs" {...props} />
    </ToastPrimitives.Close>
  );
};
