import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'zustand';
import { ProjectStoreContext } from '@studio/providers';
import { pluralize } from '@studio/utils/pluralize';
import { PROJECT_ELEMENT, ProjectElementType } from '@lib/types';
import { Tabs } from '@lib/ui';
import { OptionsPanelItems } from './options-panel-items';
import * as Styles from './options-popover-content.css';

interface OptionsPanelContentProps {
  type: ProjectElementType;
}

export const OptionsPopoverContent = (props: OptionsPanelContentProps) => {
  const { type } = props;
  const { t } = useTranslation();
  const { projectStore } = useContext(ProjectStoreContext);
  const { thumbnail, title } = useStore(projectStore);
  const [tab, setTab] = useState<ProjectElementType>(PROJECT_ELEMENT.THUMBNAIL);

  const isHook = type === PROJECT_ELEMENT.CONCEPT; // i.e formerly known as concept

  const titleLength = title.options?.length;
  const thumbLength = thumbnail.options?.length;

  if (isHook) {
    return (
      <div className={Styles.wrapper}>
        <OptionsPanelItems type={PROJECT_ELEMENT.CONCEPT} />
      </div>
    );
  }

  return (
    <Tabs.Root value={tab} className={Styles.container}>
      <Tabs.List className={Styles.tabList}>
        <Tabs.Trigger
          value={PROJECT_ELEMENT.THUMBNAIL}
          onClick={() => setTab(PROJECT_ELEMENT.THUMBNAIL)}
          className={Styles.tabLabel}
        >
          {thumbLength ?? 0}
          &nbsp;
          {t(PROJECT_ELEMENT.THUMBNAIL)}
          {pluralize(thumbLength)}
        </Tabs.Trigger>
        <Tabs.Trigger
          value={PROJECT_ELEMENT.TITLE}
          onClick={() => setTab(PROJECT_ELEMENT.TITLE)}
          className={Styles.tabLabelTitle}
        >
          {titleLength ?? 0}
          &nbsp;
          {t(PROJECT_ELEMENT.TITLE)}
          {pluralize(titleLength)}
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content
        value={PROJECT_ELEMENT.THUMBNAIL}
        className={Styles.tabContent}
        style={{
          alignContent: !thumbLength ? 'center' : undefined,
        }}
      >
        <OptionsPanelItems type={PROJECT_ELEMENT.THUMBNAIL} />
      </Tabs.Content>
      <Tabs.Content
        value={PROJECT_ELEMENT.TITLE}
        className={Styles.tabContent}
        style={{
          alignContent: !titleLength ? 'center' : undefined,
        }}
      >
        <OptionsPanelItems type={PROJECT_ELEMENT.TITLE} />
      </Tabs.Content>
    </Tabs.Root>
  );
};
