import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function HeartIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6213 26.4782C8.31651 24.3852 6.24287 22.3148 4.40578 20.2661C2.24453 17.856 1 15.0652 1 11.9571C1 9.41522 1.89357 7.14274 3.69632 5.34724C5.4984 3.5524 7.77424 2.66675 10.3158 2.66675C11.81 2.66675 13.2504 3.01315 14.6119 3.65415C15.1017 3.88478 15.5641 4.15713 16 4.46628C16.4359 4.15713 16.8982 3.88479 17.3881 3.65416C18.7496 3.01316 20.19 2.66675 21.6842 2.66675C24.2258 2.66675 26.5016 3.5524 28.3037 5.34724C30.1064 7.14274 31 9.41522 31 11.9571C31 15.0951 29.7359 17.9112 27.5439 20.3423C25.678 22.4117 23.6085 24.4656 21.3391 26.5049C21.3379 26.506 21.3366 26.5071 21.3354 26.5082L19.589 28.0875C19.0759 28.5809 18.4704 28.9522 17.7985 29.187L16.8089 26.3549L17.7987 29.1869L17.7985 29.187C17.2304 29.3855 16.6227 29.5001 15.9878 29.5001C15.3524 29.5001 14.7422 29.3853 14.1702 29.1824L15.1729 26.3549L14.17 29.1823L14.1702 29.1824C13.5016 28.9453 12.9037 28.5734 12.3979 28.0867L10.6213 26.4782ZM14.4526 25.9001L12.6381 24.2573C10.3984 22.2234 8.3988 20.2254 6.63928 18.2633C4.87976 16.3011 4 14.1991 4 11.9571C4 10.1716 4.60445 8.67688 5.81336 7.47283C7.02227 6.26877 8.52308 5.66675 10.3158 5.66675C11.3344 5.66675 12.3405 5.90063 13.334 6.36838C13.4869 6.44039 13.6374 6.51932 13.7854 6.60517C14.5986 7.07696 15.3369 7.75773 16 8.64747C16.6631 7.75773 17.4014 7.07696 18.2146 6.60517C18.3626 6.51932 18.513 6.44039 18.666 6.36838C19.6595 5.90063 20.6656 5.66675 21.6842 5.66675C23.4769 5.66675 24.9777 6.26877 26.1866 7.47283C27.3955 8.67688 28 10.1716 28 11.9571C28 14.2233 27.1053 16.3487 25.3158 18.3334C23.5263 20.3182 21.5312 22.2992 19.3303 24.2767L17.5352 25.9001"
        fill="currentColor"
      />
    </svg>
  );
}
