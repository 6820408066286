import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { useFeatureGate } from '@statsig/react-bindings';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import { hasUserSeenTour } from '../utils';

export function BrainstormTour() {
  const location = useLocation();
  const { setIsOpen, setMeta } = useTour();
  const { value: brainstormTourGate } = useFeatureGate(
    STATSIG_FEATURE_FLAGS.STUDIO_BRAINSTORM_FTUE_TOUR
  );
  const hasSeenTour = hasUserSeenTour('brainstorm');

  useEffect(() => {
    // if layout swap gate is on or tour gate's disabled or user's already seen the tour
    if (!brainstormTourGate || hasSeenTour) {
      return;
    }

    // dashboard's the only place this tour should kickoff
    const dashboardStepsActive = location.pathname.includes('/dashboard');

    if (dashboardStepsActive) {
      setIsOpen(false);
      setMeta?.('brainstorm');
      setTimeout(() => setIsOpen(true), 1000);
    }
  }, [location.pathname, brainstormTourGate, hasSeenTour]);

  return null;
}
