import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type { PersistentMessageBannerProps } from '../types';

type PersistentBannerStore = {
  banners: PersistentMessageBannerProps[];
  pushBanner: (data: PersistentMessageBannerProps) => void;
  popBanner: () => void;
  clear: () => void;
};

export const usePersistentBannerStore = create<PersistentBannerStore>()(
  devtools((set, get) => ({
    banners: [],
    pushBanner: (banner) => {
      set(
        (state) => ({
          banners: [...state.banners, banner],
        }),
        undefined,
        'banner-store/push-banner'
      );
    },
    popBanner: () => {
      set(
        (state) => ({
          banners: state.banners.slice(1) || [],
        }),
        undefined,
        'banner-store/pop-banner'
      );
    },
    clear: () => {
      set(() => ({ banners: [] }), undefined, 'banner-store/clear-banners');
    },
  }))
);
