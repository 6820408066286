import React, { createContext, useContext, useEffect, useRef } from 'react';
import * as braze from '@braze/web-sdk';
import { useAuthStore } from '@studio/features/auth';
import { useOrganizationStore } from '@studio/stores';
import { isDevelopment } from '@studio/utils/env-utils';

interface BrazeContextType {
  instance: typeof braze | null;
}

const BrazeContext = createContext<BrazeContextType | null>(null);

export const BrazeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { email, id, firstName, lastName, phoneNumber } = useAuthStore(
    (store) => ({
      id: store.user?.id,
      email: store.user?.email,
      lastName: store.user?.lastName,
      firstName: store.user?.firstName,
      phoneNumber: store.user?.phoneNumber,
    })
  );

  const { activeOrganizationId } = useOrganizationStore();

  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current) {
      const initialized = braze.initialize(import.meta.env.VITE_BRAZE_API_KEY, {
        enableLogging: isDevelopment(),
        baseUrl: import.meta.env.VITE_BRAZE_ENDPOINT,
      });

      if (!initialized) {
        console.error('Braze SDK failed to initialize.');
        return;
      }

      braze.openSession();
      isInitialized.current = true;
    }

    if (id) {
      braze.changeUser(id);
      braze.getUser()?.setEmail(email || '');
      braze
        .getUser()
        ?.setCustomUserAttribute('organizationId', activeOrganizationId);

      braze.getUser()?.setFirstName(firstName || '');
      braze.getUser()?.setLastName(lastName || '');
      braze.getUser()?.setPhoneNumber(phoneNumber || '');

      braze.requestImmediateDataFlush();
    }
  }, [email, id, activeOrganizationId, firstName, lastName, phoneNumber]);

  return (
    <BrazeContext.Provider value={{ instance: braze }}>
      {children}
    </BrazeContext.Provider>
  );
};

export const useBraze = () => {
  const context = useContext(BrazeContext);

  if (!context) {
    throw new Error('useBraze must be used within a BrazeProvider');
  }

  return context.instance;
};
