import { Trans } from 'react-i18next';
import { Flex, Heading, Text } from '@lib/ui';

export function OptionsEmptyCta() {
  return (
    <Flex
      flexDirection="column"
      gap="16px"
      height="100%"
      justifyContent="center"
      paddingBottom="100px"
    >
      <Heading as="h3" size="24" align="center" weight="bold">
        <Trans>It's empty in here!</Trans>
      </Heading>
      <Text align="center">
        <Trans>
          Click the brainstorm button and begin
          <br /> adding content to your backups!
        </Trans>
      </Text>
    </Flex>
  );
}
