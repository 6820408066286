import { StatsigClient, StatsigOptions, StatsigUser } from '@statsig/js-client';
import { getStatsigEnv } from './get-statsig-env';

let isInitialized = false;
let client: StatsigClient | null = null;

export async function getStatsigClient(user?: StatsigUser) {
  try {
    if (!client || !isInitialized) {
      client = null;
      isInitialized = false;

      const newClient = new StatsigClient(
        import.meta.env.VITE_STATSIG_STUDIO_CLIENT_KEY,
        user || {},
        { environment: getStatsigEnv() } as StatsigOptions
      );

      await newClient.initializeAsync();
      client = newClient;
      isInitialized = true;
    } else if (user) {
      try {
        await client.updateUserAsync(user);
      } catch (updateError) {
        console.error('[Statsig] Error updating user:', updateError);
        throw updateError;
      }
    }

    return client;
  } catch (error) {
    if (!isInitialized) {
      client = null;
    }
    console.error('[Statsig] Error getting client:', error);
    throw new Error(
      'Failed to initialize Statsig client: ' + (error as Error).message
    );
  }
}
