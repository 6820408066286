import { AugmentedStepType } from '../components';
import { getContentComponent, makeSelector } from '../utils';
import { TOUR_IDS } from './constants';

export function useSteps() {
  const clickOutliersFilterDropdownTrigger = () => {
    const el = document.getElementById('outliers-filter-dropdown-trigger');
    el?.click();
  };

  const stepsSansContent: Omit<AugmentedStepType, 'content'>[] = [
    {
      selector: '#root',
      title: "Discover what's\nworking with Outliers",
      body: "Find out what's working for others and use it to inspire your own next video ideas.",
      type: 'takeover',
      videoSrc: '/assets/ftue-outliers.mp4',
    },
    {
      selector: makeSelector(TOUR_IDS.OUTLIERS_CARD),
      position: 'right',
      title:
        "This video performed better than this channel's average in its first seven days.",
      body: (
        <>
          What you can do next:
          <ul className="tour-list no-bullets">
            <li>
              <span role="img" aria-hidden>
                ✨
              </span>{' '}
              <strong>Brainstorm</strong> ideas inspired by this video
            </li>
            <li>
              <span role="img" aria-hidden>
                ❤️
              </span>{' '}
              <strong>Save</strong> it to revisit later
            </li>
            <li>
              <span role="img" aria-hidden>
                ⭐
              </span>{' '}
              <strong>Follow</strong> this channel to track more Outliers
            </li>
          </ul>
        </>
      ),
      type: 'step',
    },
    {
      selector: makeSelector(TOUR_IDS.FILTER_PRESETS),
      title: 'Cut through the noise!',
      body: (
        <>
          Focus on relevant ideas with one click:
          <ul className="tour-list">
            <li>
              <strong>Audience also watched:</strong> Explore AI-curated
              channels your audience watches to uncover missed opportunities.
            </li>
            <li>
              <strong>Followed creators:</strong> See Outliers from the channels
              you follow.
            </li>
            <li>
              <strong>My videos:</strong> Review your own videos and their
              Outlier scores to brainstorm sequels or follow-ups.
            </li>
          </ul>
        </>
      ),
      type: 'step',
    },
    {
      selector: makeSelector(TOUR_IDS.SEARCH_INPUT),
      body: 'Find videos with a certain term in their title or lookup a specific channel from the dropdown after typing.',
      type: 'step',
    },
    {
      selector: makeSelector(TOUR_IDS.FILTER_DROPDOWN),
      position: 'left',
      title: 'Refine with Filters and Sorting',
      body: (
        <>
          Filters let you explore what matters most to you:
          <ul className="tour-list">
            <li>
              <strong>Cautionary Tales:</strong> See under performing videos
              (Outlier index &lt; 1).
            </li>
            <li>
              <strong>Mega Outliers:</strong> Focus on videos with an index
              above 10.
            </li>
            <li>
              <strong>Up-and-Coming Creators:</strong> Limit by subscriber
              count.
            </li>
            <li>
              <strong>Similar Length:</strong> Filter by videos matching your
              own length.
            </li>
          </ul>
          Once you've set the right filters, use Sort to find the biggest,
          newest, or most-watched Outliers first.
        </>
      ),
      type: 'step',
      actionAfter: () => {
        clickOutliersFilterDropdownTrigger();
      },
      onBefore: () => {
        clickOutliersFilterDropdownTrigger();
      },
    },
    {
      selector: '#root',
      title: "You're all set!",
      body: 'Learn more about discovering what works with our Outliers Feature Guide.',
      type: 'takeover',
      videoSrc: 'https://player.vimeo.com/video/1017203496?h=269f3e8dca',
    },
  ];

  const steps: AugmentedStepType[] = stepsSansContent.map((step) => {
    const Content = getContentComponent(step);

    return {
      ...step,
      content: () => <Content {...step} />,
      navDotAriaLabel: '',
    };
  });

  return steps;
}
