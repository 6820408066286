import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useNewProjectId } from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId } from '@studio/stores';
import { Icons } from '@lib/ui';
import client from '../../../lib/birdo-client/io';
import { PersistentMessageBanner } from '../components';
import { CAST_MEMBER_UPDATE_EGG } from '../constants';
import * as EVENTS from '../heap.constants';
import { usePersistentBannerStore } from '../store';
import type { CastMemberUpdateEgg } from '../types';

export function PersistentMessageBannerProvider() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { banners, pushBanner } = usePersistentBannerStore();
  const activeChannelUcid = useActiveChannelUcid();
  const activeOrganizationId = useActiveOrganizationId();
  const { getId } = useNewProjectId();

  useEffect(() => {
    /**
     * This is likely not the best place for this logic but I'm not
     * entirely sure where else to put it at this time.
     */
    client.on('system', (egg: CastMemberUpdateEgg) => {
      if (egg.event.eventType !== CAST_MEMBER_UPDATE_EGG) {
        return;
      }

      if (egg.event.success) {
        pushBanner({
          description: t(
            'All set! Your thumbnails are now personalized to match your style.'
          ),
          variant: 'success',
          action: {
            callback: () => {
              trackEvent(EVENTS.PERSISTENT_BANNER_NEW_BRAINSTORM_CLICK);
              navigate({
                pathname: `/app/${activeOrganizationId}/${activeChannelUcid}/projects/new-project`,
                search: createSearchParams({
                  [ProjectQueryParams.NEW_PROJECT_ID]: `${getId()}`,
                }).toString(),
              });
            },
            altText: t('Go to Brainstorm on a new or existing project'),
            text: t('Brainstorm now'),
          },
          icon: <Icons.CheckmarkIcon aria-hidden />,
        });
      } else {
        pushBanner({
          description: t(
            "We couldn't process your photo, please upload a new one to personalize thumbnails."
          ),
          variant: 'danger',
          action: {
            callback: () => {
              trackEvent(EVENTS.PERSISTENT_BANNER_CAST_SETTINGS_CLICK);
              navigate(
                `/app/${activeOrganizationId}/${activeChannelUcid}/settings/channel`
              );
            },
            altText: t('Go to Settings, then Channel, then upload a new photo'),
            text: t('Upload'),
          },
          icon: <Icons.ExclamationCircleIcon aria-hidden />,
        });
      }
    });

    return () => {
      client.off(CAST_MEMBER_UPDATE_EGG);
    };
  }, []);

  if (!banners.length) {
    return null;
  }

  /**
   * TODO: In a future PR we will dismiss the current message banner fully
   * with animation, then bring in the next in the queue with animation.
   */
  const banner = banners[0];
  return <PersistentMessageBanner {...banner} />;
}
