export const TOUR_IDS = {
  DASHBOARD_BRAINSTORM: 'dashboard-brainstorm',
  DASHBOARD_SURPRISE_ME: 'dashboard-surprise-me',
  PDP_OPTIONS_PANEL: 'pdp-options-panel',
  PDP_TOPIC_INPUT: 'pdp-topic-input',
  PDP_GENERATE_BUTTON: 'pdp-generate-button',
  PDP_IDEATION_CONTAINER: 'pdp-ideation-container',
  PDP_IDEATION_CARD_CONTAINER: 'pdp-ideation-card-container',
  PDP_IDEATION_CARD: 'pdp-ideation-card',
  PDP_KEEP_BUTTON: 'pdp-keep-button',
  PDP_IDEATION_TYPES: 'pdp-ideation-types',
  PDP_IDEATION_SPIDER_MENU: 'pdp-ideation-spider-menu',
  PDP_IDEATION_SPIDER_MENU_CONTAINER: 'pdp-ideation-spider-menu-container',
  PDP_INSPIRATION_BAR: 'pdp-inspiration-bar',
  PDP_INSPIRATION_BAR_INPUT: 'pdp-inspiration-bar-input',
  PDP_INSPIRATION_BAR_GO_BUTTON: 'pdp-inspiration-bar-go-button',
  PDP_INSPIRATION_BAR_TYPE_TRIGGER: 'pdp-inspiration-bar-type-trigger',
  PDP_INSPIRATION_BAR_TYPE_MENU: 'pdp-inspiration-bar-type-menu',
};
