import { api } from '@studio/lib';
import { SavedItemInput, SavedItem } from '@lib/types';

export const createSavedItem = async ({
  data,
  channelUcid,
  orgId,
}: SavedItemInput): Promise<SavedItem> => {
  return api.bowser.post<SavedItem>(
    `/api/orgs/${orgId}/channels/${channelUcid}/saved-items`,
    {
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export default { createSavedItem };
