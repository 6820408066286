import { useContext } from 'react';
import { useStore } from 'zustand';
import { ProjectStoreContext } from '@studio/providers';
import { PROJECT_STATUS, ProjectElementType } from '@lib/types';
import * as Styles from './options-panel-content.css';
import { OptionsEmptyCta, OptionsPanelItem } from '.';

type OptionsPanelItemsProps = {
  type: ProjectElementType;
};

export function OptionsPanelItems(props: OptionsPanelItemsProps) {
  const { type } = props;

  const { projectStore } = useContext(ProjectStoreContext);
  const { meta } = useStore(projectStore, (state) => ({
    meta: state.meta,
  }));
  const isPublished = meta.status === PROJECT_STATUS.PUBLISHED;
  const { getPrimaryOption, getNonPrimaryOptions } = useStore(projectStore);

  const primary = getPrimaryOption(type);
  const backupOptions = getNonPrimaryOptions(type);

  if (!backupOptions?.length && !primary?.id) {
    return <OptionsEmptyCta />;
  }

  return (
    <>
      {primary ? (
        <div className={`${Styles.itemContainer} current`}>
          <OptionsPanelItem
            key={`option-${primary.id}`}
            id={primary.id}
            isPrimary
            option={primary}
            type={type}
            disabled={isPublished}
          />
        </div>
      ) : null}
      {backupOptions.length > 0 ? (
        <div className={Styles.itemContainer}>
          {backupOptions.map((option) => {
            return (
              <OptionsPanelItem
                key={`option-${option.id}`}
                id={option.id}
                option={option}
                type={type}
                disabled={isPublished}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}
