import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, Label, Tooltip } from '@lib/ui';
import * as Styles from './project-elements.css';

type ProjectSectionHeaderProps = {
  children?: ReactNode;
  customIcon?: ReactNode;
  full?: boolean;
  title?: string;
  tooltip?: string;
};

export function ProjectSectionHeader(props: ProjectSectionHeaderProps) {
  const { children, customIcon, full, title, tooltip } = props;

  const [container, setContainer] = useState<HTMLDivElement | null>(
    document.body as HTMLDivElement
  );

  const { t } = useTranslation();

  const containerRef = useRef(null);

  useEffect(() => {
    setContainer(containerRef.current);
  }, []);

  return (
    <div
      className={`${Styles.sectionHeader} ${
        full ? `${Styles.fullWidth} full` : ''
      }`}
    >
      {title?.length || tooltip?.length ? (
        <Label size="xxs" weight="medium" className={Styles.sectionLabel}>
          {title?.length ? title : null}
          {tooltip?.length ? (
            <Tooltip.Provider>
              <Tooltip.Root delayDuration={200}>
                <Tooltip.Trigger asChild>
                  <div className={Styles.sectionIcon} ref={containerRef}>
                    {customIcon ?? (
                      <Icons.InfoIcon aria-label={t('More info')} />
                    )}
                  </div>
                </Tooltip.Trigger>
                <Tooltip.Portal container={container}>
                  <Tooltip.Content side="right" sideOffset={2}>
                    <Tooltip.Arrow />
                    {tooltip}
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          ) : null}
        </Label>
      ) : null}
      {children}
    </div>
  );
}
