import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { px } from '@lib/theme';
import { ProjectElementType } from '@lib/types';
import { Button, Flex, Popover } from '@lib/ui';
import { OptionsPopoverContent } from './options-popover-content';
import * as Styles from './options-trigger.css';

interface OptionsTriggerProps {
  type: ProjectElementType;
  count?: number;
}

export const OptionsTrigger = (props: OptionsTriggerProps) => {
  const { count, type } = props;
  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);

  if (!count) {
    return;
  }

  return (
    <Popover.Root modal={false}>
      <Popover.Trigger asChild>
        <Button
          size="xs"
          variant="subtle"
          fill="ghost"
          className={Styles.optionsButton}
        >
          <span>{t('Options')}</span>
          <span className={Styles.optionsLabel[type]}>{count}</span>
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="right"
          sideOffset={25}
          align="start"
          alignOffset={-40}
          className={Styles.content}
          ref={contentRef}
          onOpenAutoFocus={(event) => event.preventDefault()}
          onCloseAutoFocus={(event) => event.preventDefault()}
          onFocus={(event) => event.preventDefault()}
          onKeyDown={(event) => event.stopPropagation()}
        >
          <Flex className={Styles.panelInnerContainer}>
            <Flex gap={px(8)} alignItems="center">
              <Popover.Close className={Styles.popoverClose} />
              <span className={Styles.savedItemsLabel}>
                {t('Saved options')}
              </span>
            </Flex>
            <OptionsPopoverContent type={type} />
          </Flex>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
