import React, { forwardRef, Ref } from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import clsx from 'clsx';
import * as Styles from './slider.css';

type SliderProps = SliderPrimitive.SliderProps &
  Styles.SliderVariants & {
    className?: string;
  };

type SliderRangeProps = SliderPrimitive.SliderRangeProps & {
  className?: string;
};

type SliderThumbProps = SliderPrimitive.SliderThumbProps & {
  className?: string;
  label?: string;
};

type SliderTrackProps = SliderPrimitive.SliderTrackProps & {
  className?: string;
};

const Root = forwardRef(
  (
    { className, variant = 'primary', ...props }: SliderProps,
    ref: Ref<HTMLSpanElement> | undefined
  ) => {
    return (
      <SliderPrimitive.Root
        ref={ref}
        className={clsx(Styles.sliderVariants({ variant }), className)}
        minStepsBetweenThumbs={1}
        {...props}
      />
    );
  }
);
Root.displayName = 'SliderRoot';

const Range = forwardRef(
  (
    { className, ...props }: SliderRangeProps,
    ref: Ref<HTMLSpanElement> | undefined
  ) => {
    return (
      <SliderPrimitive.Range
        ref={ref}
        className={clsx(Styles.range, className)}
        {...props}
      />
    );
  }
);
Range.displayName = 'SliderRange';

const Thumb = forwardRef(
  (
    { className, label, ...props }: SliderThumbProps,
    ref: Ref<HTMLSpanElement> | null
  ) => {
    return (
      <SliderPrimitive.Thumb
        ref={ref}
        aria-label={label ?? 'Drag to adjust'}
        className={clsx(Styles.thumb, className)}
        {...props}
      />
    );
  }
);
Thumb.displayName = 'SliderThumb';

const Track = forwardRef(
  (
    { className, ...props }: SliderTrackProps,
    ref: Ref<HTMLSpanElement> | undefined
  ) => {
    return (
      <SliderPrimitive.Track
        ref={ref}
        className={clsx(Styles.track, className)}
        {...props}
      />
    );
  }
);
Track.displayName = 'SliderTrack';

export { Range, Root, Thumb, Track };
export type {
  SliderProps,
  SliderRangeProps,
  SliderThumbProps,
  SliderTrackProps,
};
