import { useTranslation } from 'react-i18next';
import { Flex, Heading } from '@lib/ui';
import * as Styles from './base.css';
import { ErrorScreenProps } from './error.props';

export function ErrorScreen(props: ErrorScreenProps) {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      gap="16px"
    >
      {props.status ? (
        <Heading
          as="h1"
          weight="bold"
          aria-label={t('Status Code')}
          className={Styles.status}
        >
          {props.status}
        </Heading>
      ) : null}
      {props.message ? <p className={Styles.message}>{props.message}</p> : null}
      {props.errorStatus ? (
        <p className={Styles.errorStatus}>
          {t('Error code')} :{props.errorStatus}
        </p>
      ) : null}
      {props.actions ? (
        <Flex gap="16px" className={Styles.actions}>
          {props.actions.map((action) => action)}
        </Flex>
      ) : null}
    </Flex>
  );
}
