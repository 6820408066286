import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { add } from 'date-fns';
import { DateFilter } from '@studio/components';
import {
  DISPLAY_FORMAT,
  PARAM_KEYS,
} from '@studio/features/projects/constants';
import { useParamStore } from '@studio/stores';
import { Button, Form, Icons, Popover } from '@lib/ui';
import { buildDateRangeDisplayString } from '@lib/utils';
import * as Styles from '../header.css';

// to enable selecting ranges that end in the future
const maxDate = add(new Date(), { years: 10 });

export function PublishDateFilter() {
  const { params, setParams, removeParam } = useParamStore();
  const { t } = useTranslation();

  const {
    [PARAM_KEYS.PUBLISH_DATE_START]: start,
    [PARAM_KEYS.PUBLISH_DATE_END]: end,
  } = params;
  const hasRange = start || end;
  const dateRange = hasRange
    ? buildDateRangeDisplayString(start, end)
    : t('All');

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          variant="subtle"
          size="sm"
          adornmentEnd={<Icons.ChevronDownIcon aria-hidden />}
        >
          {t('Publish Date')}:
          <span className={clsx(hasRange && Styles.PrimaryText)}>
            {' '}
            {dateRange}
          </span>
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className={Styles.PopoverContent} align="start">
          {/*
            Close doesn't render anything but we need it here to assume the initial
            focus so the calendar doesn't open immediately out of filter-date-input
          */}
          <Popover.Close />
          <Form.Root>
            <DateFilter
              minLabel={t('Start')}
              maxLabel={t('End')}
              minId={PARAM_KEYS.PUBLISH_DATE_START}
              maxId={PARAM_KEYS.PUBLISH_DATE_END}
              maxStartDate={maxDate}
              maxEndDate={maxDate}
              displayFormat={DISPLAY_FORMAT}
              params={params}
              setParams={setParams}
              removeParam={removeParam}
            />
          </Form.Root>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
